/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type PaginationResponse from "./PaginationResponse";
import type FirestoreCrop from "./FirestoreCrop";
import type PaginationRequestSearch from "./PaginationRequestSearch";
import type LinkDeleteCropRequest from "./LinkDeleteCropRequest";
import type LinkDeleteCultivarRequest from "./LinkDeleteCultivarRequest";
import type CropCultivar from "./CropCultivar";
import type CropWithGrowthStages from "./CropWithGrowthStages";
import type CropGraphs from "./CropGraphs";
import type EntityGraphs from "./EntityGraphs";
import type FirestoreCropUpsert from "./FirestoreCropUpsert";
import type CultivarGraphs from "./CultivarGraphs";
import type IdRequest from "./IdRequest";
import type GrowthStageDetails from "./GrowthStageDetails";
import type GraphClone from "./GraphClone";
import type UpsertCultivarRequest from "./UpsertCultivarRequest";
import type UpsertCropRequest from "./UpsertCropRequest";
import type GrowthStageGraphDataRequest from "./GrowthStageGraphDataRequest";
import type GraphCloneDisplay from "./GraphCloneDisplay";
import type GraphCutlivarCloneDisplay from "./GraphCutlivarCloneDisplay";
import type LabAssign from "./LabAssign";

const urls = {
    all(): string {
        return "/api/crop/all";
    },
    withCultivars(): string {
        return "/api/crop/withcultivars";
    },
    withGrowths(): string {
        return "/api/crop/withgrowths";
    },
    initial(): string {
        return "/api/crop/initial";
    },
    get(id: number): string {
        return queryUrl("/api/crop/get", {id});
    },
    getCultivar(id: number): string {
        return queryUrl("/api/crop/getcultivar", {id});
    },
    cloneCultivar(id: number): string {
        return queryUrl("/api/crop/clonecultivar", {id});
    },
    cloneCrop(id: number): string {
        return queryUrl("/api/crop/clonecrop", {id});
    }
}

export default {
    urls,
    paged(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<FirestoreCrop>>> {
        return Axios.post("/api/crop/paged", request, defaultConfig);
    },
    delete(request: LinkDeleteCropRequest): Promise<AxiosResponse> {
        return Axios.post("/api/crop/delete", request, defaultConfig);
    },
    deleteCultivar(request: LinkDeleteCultivarRequest): Promise<AxiosResponse> {
        return Axios.post("/api/crop/deletecultivar", request, defaultConfig);
    },
    all(): Promise<AxiosResponse<FirestoreCrop[]>> {
        return Axios.get("/api/crop/all", defaultConfig);
    },
    withCultivars(): Promise<AxiosResponse<CropCultivar[]>> {
        return Axios.get("/api/crop/withcultivars", defaultConfig);
    },
    withGrowths(): Promise<AxiosResponse<CropWithGrowthStages[]>> {
        return Axios.get("/api/crop/withgrowths", defaultConfig);
    },
    initial(): Promise<AxiosResponse> {
        return Axios.get("/api/crop/initial", defaultConfig);
    },
    get(id: number): Promise<AxiosResponse<CropGraphs>> {
        return Axios.get(queryUrl("/api/crop/get", {id}), defaultConfig);
    },
    getCultivar(id: number): Promise<AxiosResponse<EntityGraphs>> {
        return Axios.get(queryUrl("/api/crop/getcultivar", {id}), defaultConfig);
    },
    upsert(request: FirestoreCropUpsert): Promise<AxiosResponse> {
        return Axios.post("/api/crop/upsert", toFormData(request), defaultFormConfig);
    },
    cultivars(request: IdRequest): Promise<AxiosResponse<CultivarGraphs[]>> {
        return Axios.post("/api/crop/cultivars", request, defaultConfig);
    },
    growthStages(request: IdRequest): Promise<AxiosResponse<GrowthStageDetails[]>> {
        return Axios.post("/api/crop/growthstages", request, defaultConfig);
    },
    cloneCultivar(id: number): Promise<AxiosResponse<GraphClone>> {
        return Axios.get(queryUrl("/api/crop/clonecultivar", {id}), defaultConfig);
    },
    cloneCrop(id: number): Promise<AxiosResponse<GraphClone>> {
        return Axios.get(queryUrl("/api/crop/clonecrop", {id}), defaultConfig);
    },
    upsertCultivar(request: UpsertCultivarRequest): Promise<AxiosResponse> {
        return Axios.post("/api/crop/upsertcultivar", request, defaultConfig);
    },
    upsertCrop(request: UpsertCropRequest): Promise<AxiosResponse> {
        return Axios.post("/api/crop/upsertcrop", request, defaultConfig);
    },
    upsertGrowthStages(request: GrowthStageGraphDataRequest): Promise<AxiosResponse> {
        return Axios.post("/api/crop/upsertgrowthstages", request, defaultConfig);
    },
    pagedCropGraphs(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<GraphCloneDisplay>>> {
        return Axios.post("/api/crop/pagedcropgraphs", request, defaultConfig);
    },
    pagedCultivarGraphs(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<GraphCutlivarCloneDisplay>>> {
        return Axios.post("/api/crop/pagedcultivargraphs", request, defaultConfig);
    },
    assignLab(request: LabAssign): Promise<AxiosResponse> {
        return Axios.post("/api/crop/assignlab", request, defaultConfig);
    }
}
