/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type IdRequest from "./IdRequest";
import type AddressRequest from "./AddressRequest";
import type AddressList from "./AddressList";
import type PaginationResponse from "./PaginationResponse";
import type Address from "./Address";
import type PaginationRequestSearch from "./PaginationRequestSearch";

const urls = {
    index(): string {
        return "/api/address/index";
    }
}

export default {
    urls,
    setDefaultAddress(request: IdRequest): Promise<AxiosResponse> {
        return Axios.post("/api/address/setdefaultaddress", request, defaultConfig);
    },
    upsert(request: AddressRequest): Promise<AxiosResponse> {
        return Axios.post("/api/address/upsert", request, defaultConfig);
    },
    index(): Promise<AxiosResponse<AddressList[]>> {
        return Axios.get("/api/address/index", defaultConfig);
    },
    delete(request: IdRequest): Promise<AxiosResponse> {
        return Axios.post("/api/address/delete", request, defaultConfig);
    },
    paged(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<Address>>> {
        return Axios.post("/api/address/paged", request, defaultConfig);
    }
}
