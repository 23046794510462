import React, {useContext, useRef, useState} from "react";
import AppContext from "../appContext";
import Distributor from "../controllers/Distributor";
import {useValidation} from "../validation";
import DistributorController from "../controllers/DistributorController";
import PagedSearchTable, {PagedTableFunctions} from "../components/PagedSearchTable";
import Dialog from "../components/Dialog";
import {EditRow, EditTable} from "../components/Fields";
import Input from "../components/Input";
import {useStateSetter} from "../immutableState";
import ImageUploader from "../components/ImageUploader";
import {showSuccessOrFailed} from "../Snacks";

interface DistributorData extends Distributor {
    image: File | null;
    newImage: boolean;
}

function emptyDistributor(): DistributorData {
    return {
        id: 0,
        name: '',
        imageHash: null,
        imagePath: null,
        image: null,
        newImage: true
    }
}

const Distributors: React.FC = () => {
    const context = useContext(AppContext);
    const [popupModal, setPopupModal] = useState<boolean>(false)
    const [data, setData, updateData] = useStateSetter<DistributorData>(emptyDistributor())
    const pagedTableRef = useRef<PagedTableFunctions<Distributor>>()

    const validation = useValidation({
        name: () => data.name != null && data.name.length > 0
    });

    function newItem() {
        setData(emptyDistributor())
        setPopupModal(true)
    }

    function editRow(row: Distributor) {
        setData({...row, newImage: false, image: null})
        setPopupModal(true)
    }

    function upsert() {
        if (!validation.validate()) return;

        showSuccessOrFailed(context, DistributorController.upsert({
            name: data.name ?? '',
            image: data.image,
            id: data.id,
            newImage: data.newImage
        }))
            .then(() => {
                pagedTableRef.current?.refresh();
                setPopupModal(false);
            })
    }

    function backgroundUrl(): string {
        if (data.imageHash)
            return `/upload/distributor/${data.imagePath}-low.png?${data.imageHash}`

        // No image uploaded yet
        return '/images/noimage.jpg'
    }

    function setNewFileImage(file: File): void {
        updateData({image: file, newImage: true});
    }

    return (
        <div>
            <div className="pb-2">
                <div className="btn bg-primary" onClick={newItem}>Add Distributor</div>
            </div>
            <PagedSearchTable componentRef={pagedTableRef}
                              call={DistributorController.paged}
                              columns={[
                                  {
                                      header: 'Name',
                                      key: 'name',
                                      row: (row) => row.name
                                  },
                                  {
                                      header: <div className='text-right mr-2'>Logo</div>,
                                      key: 'logo',
                                      row: (row) => <div className='text-right'><div className="btn-sm btn-primary"
                                                                                     onClick={() => editRow(row)}>edit</div></div>
                                  },
                              ]}/>
            <Dialog title={"Distributor"}
                    show={popupModal}
                    setShow={setPopupModal}
                    body={<>
                        <EditTable discard={() => setPopupModal(false)} save={() => upsert()}
                                   saveWord={data.id === 0 ? 'insert' : 'update'}>
                            {EditRow('Name', <Input value={data.name ?? ''}
                                                    change={v => updateData({name: v})}/>, validation.rules.name, 'Name required')}
                            {EditRow('Logo', <ImageUploader imageUrl={backgroundUrl()} setImage={setNewFileImage}/>)}
                        </EditTable>
                    </>}/>
        </div>
    )
}

export default Distributors;