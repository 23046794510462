/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type DrisGraph from "./DrisGraph";
import type PaginationResponse from "./PaginationResponse";
import type PaginationRequestSearch from "./PaginationRequestSearch";
import type GraphResult from "./GraphResult";

export default {
    getAll(): Promise<AxiosResponse<DrisGraph[]>> {
        return Axios.post("/api/drisgraph/getall", defaultConfig);
    },
    paged(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<DrisGraph>>> {
        return Axios.post("/api/drisgraph/paged", request, defaultConfig);
    },
    update(graph: DrisGraph): Promise<AxiosResponse<GraphResult>> {
        return Axios.post("/api/drisgraph/update", graph, defaultConfig);
    },
    insert(graph: DrisGraph): Promise<AxiosResponse<GraphResult>> {
        return Axios.post("/api/drisgraph/insert", graph, defaultConfig);
    }
}
