/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type UserLoginCode from "./UserLoginCode";
import type LoginRequest from "./LoginRequest";
import type WindowInitial from "./WindowInitial";
import type ForgotRequest from "./ForgotRequest";
import type TokenRequest from "./TokenRequest";
import type ResetRequest from "./ResetRequest";

const urls = {
    logout(): string {
        return "/api/auth/logout";
    }
}

export default {
    urls,
    login(request: LoginRequest): Promise<AxiosResponse<UserLoginCode>> {
        return Axios.post("/api/auth/login", request, defaultConfig);
    },
    initial(): Promise<AxiosResponse<WindowInitial>> {
        return Axios.post("/api/auth/initial", defaultConfig);
    },
    logout(): Promise<AxiosResponse> {
        return Axios.get("/api/auth/logout", defaultConfig);
    },
    forgot(request: ForgotRequest): Promise<AxiosResponse<boolean>> {
        return Axios.post("/api/auth/forgot", request, defaultConfig);
    },
    checkToken(request: TokenRequest): Promise<AxiosResponse<boolean>> {
        return Axios.post("/api/auth/checktoken", request, defaultConfig);
    },
    reset(request: ResetRequest): Promise<AxiosResponse<boolean>> {
        return Axios.post("/api/auth/reset", request, defaultConfig);
    }
}
