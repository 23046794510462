/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type PaginationResponse from "./PaginationResponse";
import type PreLeafDisplayFlat from "./PreLeafDisplayFlat";
import type PaginationRequestSearch from "./PaginationRequestSearch";
import type LeafResultResponse from "./LeafResultResponse";
import type UpdateLabRequest from "./UpdateLabRequest";
import type IdRequest from "./IdRequest";
import type LeafApiData from "./LeafApiData";
import type ArchiveRequest from "./ArchiveRequest";
import type FileContentResult from "./FileContentResult";

const urls = {
    sync(): string {
        return "/api/leaf/sync";
    },
    pagedExcel(request: PaginationRequestSearch): string {
        return queryUrl("/api/leaf/pagedexcel", request);
    }
}

export default {
    urls,
    notCompleted(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<PreLeafDisplayFlat>>> {
        return Axios.post("/api/leaf/notcompleted", request, defaultConfig);
    },
    completed(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<PreLeafDisplayFlat>>> {
        return Axios.post("/api/leaf/completed", request, defaultConfig);
    },
    getLeafResults(req: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<LeafResultResponse>>> {
        return Axios.post("/api/leaf/getleafresults", req, defaultConfig);
    },
    archived(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<PreLeafDisplayFlat>>> {
        return Axios.post("/api/leaf/archived", request, defaultConfig);
    },
    updateLab(request: UpdateLabRequest): Promise<AxiosResponse> {
        return Axios.post("/api/leaf/updatelab", request, defaultConfig);
    },
    syncFirebaseCropPhotoAndLocation(request: IdRequest): Promise<AxiosResponse> {
        return Axios.post("/api/leaf/syncfirebasecropphotoandlocation", request, defaultConfig);
    },
    getApiData(request: IdRequest): Promise<AxiosResponse<LeafApiData>> {
        return Axios.post("/api/leaf/getapidata", request, defaultConfig);
    },
    resolveNames(request: IdRequest): Promise<AxiosResponse> {
        return Axios.post("/api/leaf/resolvenames", request, defaultConfig);
    },
    sync(): Promise<AxiosResponse> {
        return Axios.get("/api/leaf/sync", defaultConfig);
    },
    archive(request: ArchiveRequest): Promise<AxiosResponse> {
        return Axios.post("/api/leaf/archive", request, defaultConfig);
    },
    pagedExcel(request: PaginationRequestSearch): Promise<AxiosResponse<FileContentResult>> {
        return Axios.get(queryUrl("/api/leaf/pagedexcel", request), defaultConfig);
    }
}
