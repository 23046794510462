/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type PaginationResponse from "./PaginationResponse";
import type LabminCrop from "./LabminCrop";
import type PaginationRequestSearch from "./PaginationRequestSearch";
import type LabminSync from "./LabminSync";

const urls = {
    allCrops(): string {
        return "/api/labmin/allcrops";
    },
    sync(): string {
        return "/api/labmin/sync";
    }
}

export default {
    urls,
    paged(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<LabminCrop>>> {
        return Axios.post("/api/labmin/paged", request, defaultConfig);
    },
    allCrops(): Promise<AxiosResponse<LabminCrop[]>> {
        return Axios.get("/api/labmin/allcrops", defaultConfig);
    },
    sync(): Promise<AxiosResponse<LabminSync>> {
        return Axios.get("/api/labmin/sync", defaultConfig);
    }
}
