import React, {Dispatch, ReactElement, SetStateAction, useContext, useEffect, useState} from "react";
import AppContext from "../appContext";
import {AxiosResponse} from "axios";
import PaginationResponse from "../controllers/PaginationResponse";
import PaginationRequestSearch from "../controllers/PaginationRequestSearch";
import {dec, inc} from "./move";
import Dialog from "./Dialog";
import {classNames, wrapLoader} from "../wrapper";

interface LookProps<T> {
    show: boolean,
    setShow: (show: boolean) => void,
    select: (row: T) => void,
    call: (request: PaginationRequestSearch) => Promise<AxiosResponse<PaginationResponse<T>>>,
    heading: String;
}

function LookupName<T extends {name: string}>(props: LookProps<T>): ReactElement {
    return <Lookup show={props.show} setShow={props.setShow} select={props.select} call={props.call} heading={props.heading} renderRow={r => r.name}/>;
}

function Lookup<T>(props: LookProps<T> & {renderRow: (row: T) => React.ReactNode}): ReactElement {
    const context = useContext(AppContext);
    const [searched, setSearched] = useState<string>('')
    const [search, setSearch] = useState<string>('')
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const [items, setItems] = useState<T[]>([])
    const [total, setTotal] = useState<number>(0)
    
    useEffect(() => {
        hasResults()
        if (props.show)
            doSearch()
    }, [props.show])
    
    function keydown(ev: string) {
        if (ev === 'ArrowUp') {
            setSelectedIndex(dec(selectedIndex, items));
        }
        if (ev === 'ArrowDown') {
            setSelectedIndex(inc(selectedIndex, items));
        }
    }
    
    function hasResults() {
        return items.length > 0;
    }
    
    function cancel() {
        props.setShow(false)
        setSearch('')
        props.call({
            sortBy: '',
            ascending: true,
            page: 0,
            rowsPerPage: 200,
            search: '',
            all: false
        }).then(resp => {
            setItems(resp.data.items)
            setTotal(resp.data.total)
            setSearched('')

            setSelectedIndex(items.length === 0 ? -1 : 0);
        })
    }
    
    function doSearch() {
        // The second enter
        if (search === searched && hasResults()) {
            choose(items[selectedIndex])
            return;
        }
        
        wrapLoader(context, props.call({
            sortBy: '',
            ascending: true,
            page: 0,
            rowsPerPage: 200,
            search: search,
            all: false
        }), resp => {
            setItems(resp.items)
            setTotal(resp.total)
            setSearched(search)

            setSelectedIndex(items.length === 0 ? -1 : 0);
        })
    }
    
    function choose(row: T | undefined) {
        cancel();
        if (row) {
            props.select(row)
        }
    }

    return (
        <div>
            <Dialog title={props.heading} show={props.show} setShow={cancel} body={<div>
                <div className="flex items-center p-1 w-full">

                    <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-600 h-6 w-6" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                    </svg>

                    <input autoFocus={true} type="text" className="w-full border-none outline-none focus:ring-0" placeholder="Search query" value={search} onChange={v => setSearch(v.target.value)} onKeyDown={e => e.key == "Enter" ? doSearch() :  keydown(e.key)} />
                    <div className="btn btn-primary" onClick={doSearch}>Search</div>
                </div>

                <div className="text-xs text-gray-800 px-2 text-left">Showing {items.length} of {total} results for {searched}</div>

                {
                    hasResults() 
                        ? <table className="w-full">
                            <thead>
                                <tr className="text-gray-800 font-bold px-2 bg-gray-200 text-left">
                                    <td className='px-2'>
                                        {props.heading}
                                    </td>
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    items.map((row, index) => <tr key={index} onClick={() => choose(row)} className={classNames('cursor-pointer hover:bg-gray-300', index % 2 !== 0 ? 'bg-gray-200' : '', index === selectedIndex ? 'bg-primary-200' : '')}>
                                        <td className='px-2'>{props.renderRow(row)}</td>
                                    </tr>)
                                }
                            </tbody>
                        </table>
                        : null
                }
                
                <div className="text-right p-2 border-t sticky bottom-0 bg-white">
                    <button className="btn btn-error" onClick={() => cancel()}>
                        Cancel
                    </button>
                </div>
                
            </div>} />
        </div>
    )
}

export default LookupName;