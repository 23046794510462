import React, {
    Key,
    MutableRefObject,
    Ref,
    RefObject,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react'
import PaginationRequestSearch from '../controllers/PaginationRequestSearch'
import {AxiosResponse} from 'axios'
import PaginationResponse from '../controllers/PaginationResponse'
import Pagination from './Pagination'
import {ExclamationCircleIcon, MagnifyingGlassIcon, ExclamationTriangleIcon} from '@heroicons/react/24/outline'
import AppContext from '../appContext'
import {bind, classNames, onEnter, switcher} from '../wrapper'
import {useMountedPromise} from "../pages/lab/JobTable";
import {arrayUpdatePartial} from "../immutableState";
import FileContentResult from "../controllers/FileContentResult";
import {showSuccessOrFailed} from "../Snacks";

export interface PagedTableFunctions<T> {
    refresh: () => void;
    updateData: (update: (prev: T[]) => T[]) => void;
    updateRow: (predicate: ((row: T) => boolean), setRow: (row: T) => void) => void;
    focus: () => void;
    getData: () => T[];
}

export interface TableColumn<TResponse> {
    header: React.ReactNode | (() => React.ReactNode);
    row: (item: TResponse, index: number) => React.ReactNode;
    key?: Key;

    // return colspan 0 if column should not be rendered.
    colspan?: (item: TResponse) => number;
    classNames?: (item: TResponse) => string;
}

function isPaginationResponse<T>(obj: any): obj is PaginationResponse<T> {
    return typeof obj.total === 'number' && obj.items;
}

export function mapPaginationResponse<TFrom, TTo>(from: Promise<AxiosResponse<PaginationResponse<TFrom>>>, convert: (data: TFrom) => TTo): Promise<PaginationResponse<TTo>> {
    return from.then(data => {
        return {
            items: data.data.items.map(convert),
            total: data.data.total
        }
    })
}

export function updateItem<T extends {id: number}>(ref: RefObject<PagedTableFunctions<T>>, row: T, update: Partial<T>) {
    ref.current?.updateData(prev => {
        const found = prev.findIndex(p => p.id === row.id)
        if (found)
            return arrayUpdatePartial(prev, found, update);
        return prev;
    });
}

interface PagedTableProps<TResponse, TRequest extends PaginationRequestSearch> {
    call: ((request: TRequest) => Promise<AxiosResponse<PaginationResponse<TResponse>>>)
        | ((request: TRequest) => PaginationResponse<TResponse>)
        | ((request: TRequest) => Promise<PaginationResponse<TResponse>>);

    buildSearch?: (base: PaginationRequestSearch) => TRequest;

    topSlot?: React.ReactNode;

    componentRef?: MutableRefObject<PagedTableFunctions<TResponse> | undefined>;

    downloadExcelCall?: ((request: TRequest) => Promise<AxiosResponse<FileContentResult>>);

    keyExtractor?: (item: TResponse) => Key;
    columns: TableColumn<TResponse>[],
    rowClick?: (item: TResponse) => void;
    rowDoubleClick?: (item: TResponse) => void;
    
    inputSlot?: React.ReactNode;
    searchSlot?: React.ReactNode;

    onChange?: (rows: TResponse[], paging: PagingData) => void;
    groupBy?: (item: TResponse | undefined) => number;
}

export interface PagingData {
    page: number;
    rowsPerPage: number;
    search: string;
}

enum TableStatus { loaded, loading, error}

const PagedSearchTable = <TResponse extends object, TRequest extends PaginationRequestSearch = PaginationRequestSearch>(
    {
        call,
        keyExtractor,
        columns,
        rowClick,
        rowDoubleClick,
        buildSearch,
        topSlot,
        componentRef,
        downloadExcelCall,
        onChange,
        groupBy,
        inputSlot,
        searchSlot
    }: PagedTableProps<TResponse, TRequest>) => {
    const [data, setData] = useState<PaginationResponse<TResponse>>({
        items: [],
        total: 0
    })

    const [paging, setPaging] = useState<PagingData>({
        page: 0,
        rowsPerPage: 20,
        search: ''
    })

    const [search, setSearch] = useState('')
    const [tableState, setTableState] = useState<TableStatus>(TableStatus.loading)
    const [focusVal, setFocusVal] = useState(0)
    const {createPromise} = useMountedPromise();

    function pagingDate(page: number | null, rowsPerPage: number | null, search: string | null): PagingData {
        return {
            search: search ?? paging.search,
            rowsPerPage: rowsPerPage ?? paging.rowsPerPage,
            page: page ?? paging.page
        } as PagingData
    }

    function updateData(resp: PaginationResponse<TResponse>, set: PagingData) {
        setData(resp);
        setTableState(TableStatus.loaded);
        onChange?.(resp.items, set);
    }

    function updatePage(set: PagingData) {
        setTableState(TableStatus.loading)
        setPaging(set)

        const result = call(returnPaging(set))
        if (result instanceof Promise) {
            createPromise<AxiosResponse<PaginationResponse<TResponse>> | PaginationResponse<TResponse>>(result).then(resp => {
                // resp is AxiosResponse<PaginationResponse> | PaginationResponse
                if (isPaginationResponse<TResponse>(resp)) {
                    updateData(resp, set);
                } else {
                    updateData(resp.data, set);
                }
            }).catch(() => {
                setTableState(TableStatus.error)
            })
        } else {
            updateData(result, set);
        }
    }

    function doSearch() {
        updatePage(pagingDate(0, null, search))
    }

    useImperativeHandle(componentRef, () => ({
        refresh: doSearch,
        getData: () => data.items,
        updateData: (update: (prev: TResponse[]) => TResponse[]) => {
            const newItems = update(data.items)
            setData({...data, items: newItems})
        },
        updateRow: (predicate: ((row: TResponse) => boolean), setRow: (row: TResponse) => void) => {
            const row = data.items.find(predicate)
            if (row) {
                setRow(row)
                setData({...data, items: data.items})
            }
        },
        focus
    }))

    const input = useRef<HTMLInputElement>(null)
    const app = useContext(AppContext)

    // Rerun the useEffect to focus on input
    function focus() {
        setFocusVal(Math.random())
    }

    useEffect(() => {
        // fire and focus on focus function.
        input.current?.focus()
    }, [focusVal])

    useEffect(() => {
        // useEffect is fired after initial render and setRef
        updatePage(paging)
    }, [])

    function handleRowClick(row: TResponse) {
        rowClick?.(row)
    }

    function handleDoubleClickRow(row: TResponse) {
        rowDoubleClick?.(row)
    }

    function returnPaging(set: PagingData): TRequest {
        const req = {
            sortBy: '',
            search: search,
            rowsPerPage: set.rowsPerPage,
            page: set.page,
            ascending: false,
            all: false
        }

        return buildSearch ? buildSearch(req) : req as TRequest
    }

    function groupSplit(item: { resp: TResponse, split: boolean }) {
        return item.split ? 'border-t-4 border-primary' : '';
    }

    return (
        <div className="w-full">

            <div className="rounded-md border py-1 bg-white relative">
                {topSlot}
                {
                    switcher(tableState, c => c
                        .case(s => s === TableStatus.loaded || s === TableStatus.loading, () =>
                            <>
                                {tableState === TableStatus.loading
                                    ? <div
                                        className="absolute inset-0 bg-overlay-200 flex justify-center items-center">
                                        <img src='/images/loader.gif' alt=''/>
                                    </div>
                                    : null}
                                <div className="flex w-full bg-white items-center">
                                    <div className="px-2 text-gray-400">
                                        <MagnifyingGlassIcon height={24}/>
                                    </div>
                                    <div className="w-full">
                                        <input ref={input} type="text" className="w-full outline-none"
                                               onKeyUp={onEnter(doSearch)} {...bind(search, setSearch)}/>
                                        {inputSlot}
                                    </div>
                                    {
                                        downloadExcelCall
                                            ? <div className='px-2 p-1 cursor-pointer hover:bg-gray-100 ' onClick={() => showSuccessOrFailed(app, downloadExcelCall(returnPaging(paging)), 'Starting download').then(resp => {
                                                if (resp.config.url !== undefined) {
                                                    window.location.href = resp.config.url
                                                }
                                            })}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                                     viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                                                </svg>
                                            </div>
                                            : null
                                    }
                                    {searchSlot}
                                    <div className="btn bg-primary" onClick={doSearch}>search</div>
                                </div>

                                <div className="w-full overflow-x-scroll">
                                    <table className="min-w-full divide-y divide-gray-300 w-full">
                                        <thead className="bg-gray-50">
                                        <tr>
                                            {columns.map((c, i) => (
                                                <th scope="col"
                                                    className="uppercase px-2 py-2 text-gray-500 text-left text-sm font-semibold "
                                                    key={i}>{typeof c.header === 'function' ? c.header() : c.header}</th>))}
                                        </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                        {data.items.map((row, index) => (
                                            <tr key={keyExtractor?.(row) ?? (paging.page * paging.rowsPerPage) + index}
                                                className={classNames(index % 2 === 0 ? '' : 'bg-gray-50', 'group hover:bg-gray-200 cursor-pointer', groupSplit({resp: row, split: groupBy?.(row) !== groupBy?.(data.items[index - 1])}))}
                                                onClick={() => handleRowClick(row)}
                                                onDoubleClick={() => handleDoubleClickRow(row)}
                                            >


                                                {columns.map((c, i) => {
                                                        const colspan = c.colspan?.(row) ?? 1;
                                                        if (colspan == 0)
                                                            return null;

                                                        return (
                                                            <td colSpan={colspan}
                                                                className={classNames('whitespace-nowrap px-1 py-2 text-sm text-gray-500', c.classNames?.(row))}
                                                                key={i}>{c.row(row, index)}</td>)
                                                    }
                                                )}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>

                                </div>
                                <Pagination refresh={() => componentRef?.current?.refresh()} total={data.total}
                                            rowsPerPage={paging.rowsPerPage}
                                            page={paging.page}

                                            gotoPage={(page) => updatePage(pagingDate(page, null, null))}
                                            setRowsPerPage={rows => updatePage(pagingDate(0, rows, null))}/>
                            </>
                        )
                        .case(TableStatus.error, () => <div className="flex w-full m-4 justify-center items-center">
                            <div className="px-2"><ExclamationTriangleIcon className="w-10 h-10 text-red-400"/></div>
                            <div className="text-gray-600">Error loading table data</div>
                        </div>)
                    )
                }

            </div>
        </div>
    )
}

export default PagedSearchTable
