import { useState } from 'react'
import {ValidatedRules} from "../validation";

type ValidationRuleTyped<T> = ((entry: T) => boolean) | boolean;
type ValidationRulesTyped<T> = Record<string, ValidationRuleTyped<T>>;

function runRule<T> (object: T, rule: ValidationRuleTyped<T>): boolean {
    if (typeof rule === 'boolean') { return rule }
    return rule(object)
}


export type ValidationArrayRet<T, G> = {
    data: T;
    rules: ValidatedRules<G>
}

function allTrue (rules: any): boolean {
    for (const k in rules) {
        if (!rules.hasOwnProperty(k))
            continue;

        if (!rules[k])
            return false;
    }
    return true;
}

function run<TRules, T> (rules: TRules, arr: T[], runRule: (entry: T, rule: ValidationRuleTyped<T>) => boolean): {items: (ValidationArrayRet<T, TRules>)[], passed: boolean} {
    const items = arr.map<ValidationArrayRet<T, TRules>>((item, index) => {
        const r: any = {}
        for (const k in rules) {
            r[k] = runRule(item, rules[k] as any)
        }
        return {
            data: item,
            rules: r
        }
    });

    return {
        items,
        passed: items.every(item => allTrue(item.rules))
    }
}

export function useValidationArray<T>(arr: T[]): <G extends ValidationRulesTyped<T>>(rules: G) => {
    validate: () => boolean;
    clear: () => void;
    items: ValidationArrayRet<T, G>[];
    dirtyPassed: boolean;
} {
    return (rules) => {
        const [realTime, setRealTime] = useState(false)

        function validate (): boolean {
            setRealTime(true)
            return run(rules, arr, runRule).passed
        }

        function clear (): void {
            setRealTime(false)
        }

        const validated = realTime
            ? run(rules, arr, runRule)
            : run(rules,  arr,() => true)

        return {
            clear,
            validate,
            dirtyPassed: validated.passed,
            items: validated.items
        }
    };
}