import React, {PropsWithChildren, ReactNode, useRef, useState} from 'react'
import PermissionEnum from './controllers/PermissionEnum'
import {isLocalhost} from './controllers/helper'
import DialogPortal from "./components/DialogPortal";
import WindowInitial from "./controllers/WindowInitial";
import Snacks, {SnackOperations} from "./Snacks";
import AppContext from './appContext'

const Loader: React.FC = () => {
    return (
        <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen"
                      aria-hidden="true">&#8203;</span>

                <div className="mx-3 w-12 h-12 bg-primary-500 animate-spin inline-block"></div>
                <div className="mx-3 w-12 h-12 bg-primary-500 animate-pulse inline-block"></div>
                <div className="mx-3 w-12 h-12 bg-primary-500 animate-ping inline-block"></div>
                <div className="mx-3 w-12 h-12 bg-primary-500 animate-bounce inline-block"></div>
            </div>
        </div>
    )
}

// keep count of how many loaders there are
let count = 0

const AppContextProvider: React.FC<PropsWithChildren<{ initial: WindowInitial }>> = ({
                                                                      children,
                                                                      initial
                                                                  }) => {
    const [loading, setLoading] = useState(false)
    const snacksRef = useRef<SnackOperations>(null)

    function showLoader(): () => void {
        setLoading(true)
        count++
        return () => {
            if (--count === 0) {
                setLoading(false)
            }
        }
    }

    function showSnack(node: ReactNode, timeout?: number): () => void {
        if (snacksRef.current) {
            return snacksRef.current.showSnack(node, timeout ?? 5000)
        }

        return () => {
        }
    }

    function hasPermission(permission: PermissionEnum): boolean {
        return !!initial.permissions.find(s => s === permission || s === PermissionEnum.Admin)
    }

    return (
        <AppContext.Provider value={{
            hasPermission,
            isLoading: loading,
            showLoader,
            initial,
            showSnack
        }}>
            <DialogPortal>
                {loading ? <Loader/> : null}
                <Snacks ref={snacksRef}/>
            </DialogPortal>

            {children}
        </AppContext.Provider>
    )
}

export default AppContextProvider
