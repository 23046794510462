/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type Language from "./Language";
import type PaginationResponse from "./PaginationResponse";
import type PaginationRequestSearch from "./PaginationRequestSearch";
import type KeysLookup from "./KeysLookup";
import type LangResult from "./LangResult";
import type LanguageTextRequest from "./LanguageTextRequest";
import type KeyDisplayRequest from "./KeyDisplayRequest";
import type LanguageInsertRequest from "./LanguageInsertRequest";

const urls = {
    index(): string {
        return "/api/language/index";
    },
    keysLookup(): string {
        return "/api/language/keyslookup";
    }
}

export default {
    urls,
    index(): Promise<AxiosResponse<Language[]>> {
        return Axios.get("/api/language/index", defaultConfig);
    },
    languages(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<Language>>> {
        return Axios.post("/api/language/languages", request, defaultConfig);
    },
    keysLookup(): Promise<AxiosResponse<KeysLookup[]>> {
        return Axios.get("/api/language/keyslookup", defaultConfig);
    },
    keys(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<LangResult>>> {
        return Axios.post("/api/language/keys", request, defaultConfig);
    },
    upsertLanguage(request: Language): Promise<AxiosResponse> {
        return Axios.post("/api/language/upsertlanguage", request, defaultConfig);
    },
    upsertKeyText(request: LanguageTextRequest): Promise<AxiosResponse> {
        return Axios.post("/api/language/upsertkeytext", request, defaultConfig);
    },
    changeDisplay(request: KeyDisplayRequest): Promise<AxiosResponse> {
        return Axios.post("/api/language/changedisplay", request, defaultConfig);
    },
    addNewKey(request: LanguageInsertRequest): Promise<AxiosResponse> {
        return Axios.post("/api/language/addnewkey", request, defaultConfig);
    },
    deleteKey(keyId: number): Promise<AxiosResponse> {
        return Axios.post("/api/language/deletekey", keyId, defaultConfig);
    }
}
