import PagedSearchTable, {PagedTableFunctions, TableColumn} from "./PagedSearchTable";
import React, {Key, MutableRefObject} from "react";
import PaginationResponse from "../controllers/PaginationResponse";
import PaginationRequestSearch from "../controllers/PaginationRequestSearch";

interface PagedTableProps<TResponse> {
    data: TResponse[];
    topSlot?: React.ReactNode;
    componentRef?: MutableRefObject<PagedTableFunctions<TResponse> | undefined>;
    keyExtractor?: (item: TResponse) => Key;
    columns: TableColumn<TResponse>[],
    rowClick?: (item: TResponse) => void
}

function searchProperties(obj: object, search: string): boolean {
    for (const k in obj) {
        if (obj.hasOwnProperty(k)) {
            const val = (obj as any)[k];
            if (val?.toString().toLowerCase().includes(search.toLowerCase()))
                return true;
        }
    }
    return false;
}

const PagedTable = <TResponse extends object>(props: PagedTableProps<TResponse>) => {

    function call(request: PaginationRequestSearch): PaginationResponse<TResponse> {
        const start = request.page * request.rowsPerPage;
        
        const searched = request.search == '' 
            ? props.data
            : props.data.filter(d => searchProperties(d, request.search ?? '')); 
        
        return {
            items: searched.slice(start, start + request.rowsPerPage),
            total: searched.length
        }
    }

    return <PagedSearchTable
        call={call}
        columns={props.columns}
        keyExtractor={props.keyExtractor}
        topSlot={props.topSlot}
        componentRef={props.componentRef}
    />

}

export default PagedTable;
