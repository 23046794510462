import React, {Fragment, PropsWithChildren, useContext, useMemo} from 'react'
import AppContext from '../appContext'
import {chunk} from "../array";

export interface FieldEntry {
    label: string | React.ReactNode,
    value: React.ReactNode,
}

export type KeyOfType<T, V> = keyof {
    [P in keyof T as T[P] extends V ? P : never]: any
};

// focus to the first input or select element inside el
export function focusFirstInput(el: HTMLDivElement) {
    const first = el.querySelector('input,select');
    (first as (HTMLInputElement | HTMLSelectElement))?.focus()
}

export function EditRow(label: string, node: React.ReactNode, rule: boolean = false, errorMessage: string = '') {
    return <tr>
        <td className="align-top">
            <div className="py-1 pr-16 text-gray-800">
                {label}
            </div>
        </td>
        <td>
            {!rule ? <div className="text-error-600">{errorMessage}</div> : null}
            {node}
        </td>
    </tr>
}

// wrapper for common crud structure
export const EditTable: React.FC<PropsWithChildren<{
    discard: () => void;
    save: () => void;
    saveWord: string;
}>> = (props) => {
    const context = useContext(AppContext)
    return <div onKeyUp={event => event.key === 'Enter' ? props.save() : null}>
        <table className="m-4">
            <tbody>
            {props.children}
            </tbody>
        </table>
        <div className="text-right p-2 border-t sticky bottom-0 bg-white">
            <div className="btn bg-red-500" onClick={() => props.discard()}>discard</div>
            <div className="btn bg-primary-500"
                 onClick={() => props.save()}>{props.saveWord}</div>
        </div>
    </div>
}

export const Fields: React.FC<{ fields: (FieldEntry | null)[], columns: number }> = (props) => {
    const app = useContext(AppContext)

    const chunked = useMemo(() => chunk(props.fields.filter(f => f != null) as FieldEntry[], props.columns), [props.fields]);

    return (<table className="p-4 w-full">
        <tbody>
        {chunked.map((row, rowIndex) => (
            <tr key={'row' + rowIndex}>
                {row.map((field, colIndex) =>
                    <Fragment key={colIndex}>
                        <td className="align-top">
                            <div className="py-3 pr-16 text-gray-800">
                                {field.label}
                            </div>
                        </td>
                        <td>{field.value}</td>
                    </Fragment>
                )}
            </tr>
        ))}
        </tbody>
    </table>)
}
