import React, {useContext, useEffect, useRef, useState} from "react";
import CheckBox from "../../components/CheckBox";
import Input from "../../components/Input";
import Dialog from "../../components/Dialog";
import CargoCropDisplay from "../../controllers/CargoCropDisplay";
import CarbohydrateController from "../../controllers/CarbohydrateController";
import RegionDetails from "../../controllers/RegionDetails";
import RegionGrowthsTable, {GrowthsTableProps} from "./GrowthsTable";
import AppContext from "../../appContext";
import {arrayUpdatePartial} from "../../immutableState";
import WarningPopup, {useWarningState} from "../../components/WarningPopup";
import IdName from "../../controllers/IdName";
import TestCarboCropGrowth from "../../controllers/TestCarboCropGrowth";
import CarboCropGrowthDisplay from "../../controllers/CarboCropGrowthDisplay";



const RegionsDialog: React.FC<{
    regionSelectedCrop: CargoCropDisplay | null,
    setRegionSelectedCrop: (v: CargoCropDisplay | null) => void,
    regions: RegionDetails[],
    availableGrowthStages: CarboCropGrowthDisplay[],
    setRegions: (v: RegionDetails[]) => void, // use this when updating the regions
}> = (props) => {
    const context = useContext(AppContext)
    const [regions, setRegions] = useState<RegionDetails[]>(props.regions)
    const warningState = useWarningState<number>(0);

    //set Refs for children
    const regionGrowthsTableRefs = useRef<GrowthsTableProps[]>([]);


    useEffect(() => {
        setRegions(props.regions)
    }, [props.regions])
    function newRegion() {
        if (props.regionSelectedCrop === null) return
        const hideLoader = context.showLoader()
        CarbohydrateController
            .addRegion({cropId: props.regionSelectedCrop.id, name: ""})
            .then(resp => {
                setRegions([...regions, {
                    id: resp.data,
                    name: "",
                    cropId: props.regionSelectedCrop?.id!,
                    growthValues: [],
                    linkedTissueId: -1
                }])
                // setRegions()
            })
            .finally(hideLoader)
    }

    function updateRegions() {
        const hideLoader = context.showLoader()
        // getall children ref data
        const growthValues = regionGrowthsTableRefs.current.map(r => r.getData())
        const request: RegionDetails[] = regions.map((r, index) => {
            return {
                id: r.id,
                name: r.name,
                cropId: r.cropId,
                growthValues: growthValues[index]!, // must exists
                linkedTissueId: r.linkedTissueId
            }
        });

        CarbohydrateController
            .updateRegions(request)
            .then(resp => {
                props.setRegions([])
                props.setRegionSelectedCrop(null)
            })
            .finally(hideLoader)
    }

    function deleteRegion() {
        const hideLoader = context.showLoader()
        CarbohydrateController
            .deleteRegion({id: warningState.state.data})
            .then(() => {
                setRegions(regions.filter(r => r.id !== warningState.state.data))
            })
            .finally(hideLoader)
    }

    return <Dialog title="Regions" show={props.regionSelectedCrop != null} setShow={() => props.setRegionSelectedCrop(null)}>
        {
            regions.map((region, index) => <div key={region.id} className='m-2 p-2 border' >
                <div className='flex'>
                    <span>Region Name</span>
                    <Input className='' value={region.name} change={v => {
                        setRegions(arrayUpdatePartial(regions, index, {name: v}))
                    }}/>
                    {/*<button className="m-1 btn-error btn-sm" onClick={() => warningState.show("Are you sure you want to delete " + region.name, region.id)}>x</button>*/}
                </div>
                <RegionGrowthsTable ref={el => (regionGrowthsTableRefs.current[index] = el!)} 
                                    key={index} 
                                    availableGrowthStages={props.availableGrowthStages} 
                                    growths={region.growthValues} />
            </div>)
        }
        <div className="btn-sm btn-primary m-2" onClick={() => newRegion()}>+</div>

        <div className="text-right p-2 border-t sticky bottom-0 bg-white">
            <button className="btn btn-error" onClick={() => props.setRegionSelectedCrop(null)}>
                Cancel
            </button>
            <div className="btn btn-primary" onClick={updateRegions}>
                Update
            </div>
        </div>
        <WarningPopup state={warningState}
                      onYes={deleteRegion}/>
    </Dialog>
}

export default RegionsDialog;
