/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type FirestoreAgronomist from "./FirestoreAgronomist";
import type PaginationResponse from "./PaginationResponse";
import type PaginationRequestSearch from "./PaginationRequestSearch";
import type IdRequest from "./IdRequest";

const urls = {
    index(): string {
        return "/api/agronomist/index";
    }
}

export default {
    urls,
    index(): Promise<AxiosResponse<FirestoreAgronomist[]>> {
        return Axios.get("/api/agronomist/index", defaultConfig);
    },
    paged(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<FirestoreAgronomist>>> {
        return Axios.post("/api/agronomist/paged", request, defaultConfig);
    },
    archive(request: IdRequest): Promise<AxiosResponse> {
        return Axios.post("/api/agronomist/archive", request, defaultConfig);
    }
}
