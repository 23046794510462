import React, {useContext, useRef} from "react";
import AppContext from "../appContext";
import {wrapLoader} from "../wrapper";
import LabminController from "../controllers/LabminController";
import PagedSearchTable, {PagedTableFunctions} from "../components/PagedSearchTable";
import LabminCrop from "../controllers/LabminCrop";
import {showSuccessOrFailed} from "../Snacks";

const Labmin: React.FC = () => {
    const context = useContext(AppContext);

    const pagedTableRef = useRef<PagedTableFunctions<LabminCrop>>()

    function sync() {
        showSuccessOrFailed(context, LabminController.sync()).then(() => {
            pagedTableRef.current?.refresh()
        })
    }

    return (
        <div className="bg-white p-2 shadow">
            <div className="btn btn-primary mb-2"
                 onClick={sync}>Sync
            </div>
            <PagedSearchTable componentRef={pagedTableRef} call={LabminController.paged} keyExtractor={i => i.id}
                              columns={[
                                  {
                                      header: 'Name',
                                      row: item => item.name
                                  },
                                  {
                                      header: 'Short Code',
                                      row: item => item.shortCode
                                  },
                                  {
                                      header: 'Lab',
                                      row: item => item.lab
                                  }
                              ]}/>
        </div>
    )
}

export default Labmin;