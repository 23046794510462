import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import AppContext from "../../appContext";
import CarbohydrateController from "../../controllers/CarbohydrateController";
import CargoCropDisplay from "../../controllers/CargoCropDisplay";
import TestCarboCropGrowth from "../../controllers/TestCarboCropGrowth";
import PagedSearchTable, {PagedTableFunctions} from "../../components/PagedSearchTable";
import Dialog from "../../components/Dialog";
import {EditRow, EditTable} from "../../components/Fields";
import Input from "../../components/Input";
import {classNames, wrapLoader} from "../../wrapper";
import Number from "../../components/Number";
import {showSuccessOrFailed} from "../../Snacks";
import CheckBox from "../../components/CheckBox";
import CropController from "../../controllers/CropController";
import ElementValue from "../../controllers/ElementValue";
import {useValidation} from "../../validation";
import RegionDetails from "../../controllers/RegionDetails";
import RegionsDialog from "./RegionsDialog";
import type IdName from "../../controllers/IdName";
import CultivarGrowthDetails from "../../controllers/CultivarGrowthDetails";
import CultivarsDialog from "./CultivarsDialog";
import CarbohydrateTissueController from "../../controllers/CarbohydrateTissueController";
import SelectNumberNullable from "../../components/SelectNumberNullable";
import SelectNumber from "../../components/SelectNumber";
import CarboCropGrowthDisplay from "../../controllers/CarboCropGrowthDisplay";
import {arrayUpdatePartial} from "../../immutableState";

function emptyElementValue(): ElementValue {
    return {
        min: 0,
        med: 0,
        max: 0
    }
}

const threeBarIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                          stroke="gray" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
</svg>


function emptyCarboCropGrowthDisplay(firstLinkedTissueId: number): CarboCropGrowthDisplay {
    return {
        id: 0,
        sequenceNumber: 0,
        name: "",
        sugar: emptyElementValue(),
        starch: emptyElementValue(),
        linkedTissueId: firstLinkedTissueId,
        linkedTissueName: '',
        displayOnReport: false
    }
}

function emptyCargoCropDisplay(): CargoCropDisplay {
    return {
        id: 0,
        name: '',
        growthCounts: 0,
        active: false,
        factor: 0
    }
}

const GrowthStages: React.FC = () => {
    const context = useContext(AppContext);
    const pagedTableRef = useRef<PagedTableFunctions<CargoCropDisplay>>()

    const [showGrowths, setShowGrowths] = useState<boolean>(false)
    const [showEditGrowth, setShowEditGrowth] = useState<boolean>(false)
    const [assignGrowth, setAssignGrowth] = useState<boolean>(false)
    const [growths, setGrowths] = useState<CarboCropGrowthDisplay[]>()
    const [growth, setGrowth] = useState<CarboCropGrowthDisplay>(emptyCarboCropGrowthDisplay(0))
    const [selectedCrop, setSelectedCrop] = useState<CargoCropDisplay>(emptyCargoCropDisplay())
    const [tissues, setTissues] = useState<IdName[]>([])
    
    const [selectedGrowth, setSelectedGrowth] = useState<CargoCropDisplay>(emptyCargoCropDisplay())
    const [newGrowthId, setNewGrowthId] = useState<number>(0)
    const [cropImage, setCropImage] = useState<{ imageHash: string, imagePath: string }>({imagePath: '', imageHash: ''})

    // Regions dialog
    const [regions, setRegions] = useState<RegionDetails[]>([])
    const [availableGrowthStages, setAvailableGrowthStages] = useState<CarboCropGrowthDisplay[]>([])
    const [regionSelectedCrop, setRegionSelectedCrop] = useState<CargoCropDisplay | null>(null)

    // Cultivars dialog
    const [cultivars, setCultivars] = useState<CultivarGrowthDetails[]>([])
    const [cultivarSelectedCrop, setCultivarSelectedCrop] = useState<CargoCropDisplay | null>(null)

    const [draggedItem, setDraggedItem] = useState<CarboCropGrowthDisplay | null>(null);
    const [draggedOverIndex, setDraggedOverIndex] = useState<number | null>(null);

    const validation = useValidation({
        reassigned: () => newGrowthId !== 0
    })
    
    const firstTissueId = useMemo(() => {
        return tissues.length > 0 ? tissues[0]!.id : 0
    }, [tissues])
    
    useEffect(() => {
        const hideLoader = context.showLoader()
        CarbohydrateTissueController
            .getActiveTissues()
            .then(resp => {
                setTissues(resp.data)
                setGrowth({...growth, linkedTissueId: firstTissueId})
            })
            .finally(hideLoader)
    }, [])
    function cancelGrowthAssign() {
        setAssignGrowth(false)
        setNewGrowthId(0)
    }

    function selectNewGrowth(row: any) {
        setAssignGrowth(true)
        setSelectedGrowth({...row})
    }

    function removeGrowth() {
        if (!validation.validate()) return

        wrapLoader(context, CarbohydrateController.cropGrowthsRemove({id: selectedGrowth.id, assignId: newGrowthId}), resp => {
            cancelGrowthAssign()
            editGrowths(selectedCrop)
            pagedTableRef.current?.refresh();
        })
    }

    function cancelGrowth() {
        setShowEditGrowth(false)
    }

    function cancelGrowths() {
        setShowGrowths(false)
    }

    function upsertGrowth() {
        showSuccessOrFailed(context, 
            CarbohydrateController
                .cropGrowthsUpsert({...growth, cropId: selectedCrop.id ,name: growth.name ?? ''}))
                .then((resp) => {
                    setGrowths(resp.data)
                    cancelGrowth()
                })
    }

    function editGrowth(row: CarboCropGrowthDisplay) {
        setGrowth({...row})
        setShowEditGrowth(true)
    }

    function editCultivars(row: CargoCropDisplay) {
        wrapLoader(context, CarbohydrateController.cultivarGrowth({id: row.id}), resp => {
            setAvailableGrowthStages(resp.availableGrowthStages)
            setCultivars(resp.cultivarGrowths)
            setCultivarSelectedCrop(row)
        })
    }

    function editGrowths(row: CargoCropDisplay) {
        setSelectedCrop({...row})
        wrapLoader(context, CarbohydrateController.cropGrowths({id: row.id}), resp => {
            setGrowths(resp)
            setShowGrowths(true)
        })
        CropController.get(row.id).then(resp => setCropImage({imageHash: resp.data.imageHash, imagePath: resp.data.imagePath}));
    }

    function updateCrop() {
        showSuccessOrFailed(context, CropController.upsertCrop({cropId: selectedCrop.id, factor: selectedCrop.factor, sequenceNumbersIds: growths?.map(g => ({id: g.id,sequenceNumber: g.sequenceNumber, displayOnReport: g.displayOnReport})) ?? []}))
            .then(() => {
                cancelGrowths()
                pagedTableRef.current?.refresh();
            })
    }

    function openRegions(item: CargoCropDisplay) {
        const hideLoader = context.showLoader()
        CarbohydrateController
            .getRegions({id: item.id})
            .then(resp => {
                setRegions(resp.data.regions)
                setAvailableGrowthStages(resp.data.availableGrowthStages)
                setRegionSelectedCrop(item)
            })
            .finally(hideLoader)
    }

    function onDragEnd() {
        if (!growths) return;
        
        if (draggedOverIndex !== null && draggedItem !== null) {
            const newGrowths = [...growths];
            newGrowths.splice(growths.indexOf(draggedItem), 1);
            newGrowths.splice(draggedOverIndex, 0, draggedItem);
            setGrowths(newGrowths.map((g , i) => ({...g, sequenceNumber: i + 1}))); // the sequence starts at 1
        }
        setDraggedItem(null);
        setDraggedOverIndex(null);
    }

    function onDragOver(index: number) {
        if (index === draggedOverIndex) return;
        setDraggedOverIndex(index);
    }

    function organNamesFromId(linkedTissueId: number | null) {
        return tissues.find(t => linkedTissueId == t.id)?.name ?? ''
    }

    return (
        <div className="bg-white p-2 shadow">
            <PagedSearchTable componentRef={pagedTableRef} call={CarbohydrateController.crops} columns={[
                {
                    header: 'Name',
                    row: item => item.name
                },
                {
                    header: 'Factor',
                    row: item => item.factor
                },
                {
                    header: 'Growths',
                    row: item => item.growthCounts
                },
                {
                    header: 'Active',
                    row: item => <CheckBox checked={item.active} onChange={v => {
                        pagedTableRef.current?.updateRow((rowPred) => {
                            return rowPred === item;
                        }, row => {
                            row.active = v
                        })
                        showSuccessOrFailed(context, CarbohydrateController.updateCropActive({id: item.id, active: !item.active}))
                    }}/>
                },
                {
                    header: <div className='text-right'>Actions</div>,
                    row: item => <div className='text-right'>
                        <div className="btn-primary btn-sm" onClick={() => openRegions(item)}>
                            region
                        </div>
                        <div className="btn-primary btn-sm" onClick={() => editCultivars(item)}>
                            cultivars
                        </div>
                        <div className="btn-primary btn-sm" onClick={() => editGrowths(item)}>
                            growths
                        </div>
                    </div>
                },
            ]}/>

         <Dialog title={`Crop Growth for ${selectedCrop.name}`} show={showGrowths} setShow={() => cancelGrowths()}
                 body={<div className=''>
                     <div className='flex items-center justify-center'>
                         <img className='rounded m-2' src={`/upload/crops/${cropImage.imagePath}-low.png?${cropImage.imageHash}`} alt="cropImage"/>
                     </div>
                     <div className='flex items-center justify-center'>
                         <tr className='m-2'>
                             <td className="align-top">
                                 <div className="py-2 pr-3 text-gray-800">
                                     Crop Factor
                                 </div>
                             </td>
                             <td>
                                 <Number value={selectedCrop.factor} change={(val) => setSelectedCrop({...selectedCrop, factor: val})}/>
                             </td>
                         </tr>
                     </div>
                 <table className="divide-y divide-gray-300  m-2">

                     {growths && growths?.length > 0
                         ? <thead className="bg-gray-50">
                             <tr>
                                 <td className="uppercase pr-5 py-2 text-gray-500 text-left text-sm font-semibold"></td>
                                 <td className="uppercase pr-5 py-2 text-gray-500 text-left text-sm font-semibold">Order</td>
                                 <td className="uppercase pr-5 py-2 text-gray-500 text-left text-sm font-semibold">Name</td>
                                 <td className="uppercase pr-5 py-2 text-gray-500 text-left text-sm font-semibold whitespace-nowrap">Organ Types</td>
                                 <td className="uppercase py-2 text-gray-500 text-left text-sm font-semibold">Sugar</td>
                                 <td className="uppercase py-2 text-gray-500 text-left text-sm font-semibold">Starch</td>
                                 <td className="uppercase py-2 text-gray-500 text-left text-sm font-semibold whitespace-nowrap">Display</td>
                                 <td className="text-right uppercase pr-2 py-2 text-gray-500 text-sm font-semibold">Actions</td>
                             </tr>
                             <tr className=''>
                                 <td></td>
                                 <td></td>
                                 <td></td>
                                 <td></td>
                                 <td className="uppercase pr-2 pb-2 text-gray-500 text-left text-sm font-semibold">
                                     <div className='flex min-w-[200px]'>
                                         <div className='w-1/2'>min</div>
                                         <div className='w-1/2'>max</div>
                                     </div>
                                 </td>
                                 <td className="uppercase pr-2 pb-2 text-gray-500 text-left text-sm font-semibold">
                                     <div className='flex min-w-[200px]'>
                                         <div className='w-1/2'>min</div>
                                         <div className='w-1/2'>max</div>
                                     </div>
                                 </td>
                                 <td className="uppercase text-gray-500 text-left text-sm font-semibold whitespace-nowrap">on report</td>
                                 <td></td>
                             </tr>
                         </thead>
                         : null
                     }
                     <tbody className="bg-white">
                        {
                            growths?.map((row, index) => <tr
                                key={row.id}
                                draggable={true}
                                onDragStart={() => setDraggedItem(row)}
                                onDragOver={e => {
                                    e.preventDefault();
                                    onDragOver(index)
                                }}
                                onDragEnd={onDragEnd}
                                className={classNames(
                                    index % 2 === 0 ? "" : "bg-gray-50",
                                    "group hover:bg-gray-200 cursor-pointer",
                                )}
                            >
                                <td className='pr-3 py-1 whitespace-nowrap'>{threeBarIcon}</td>
                                <td className='pr-3 py-1 whitespace-nowrap'>{row.sequenceNumber}</td>
                                <td className='pr-3 py-1 whitespace-nowrap'>{row.name}</td>
                                <td className="pr-3 py-1 whitespace-nowrap">
                                    {row.linkedTissueName}
                                </td>
                                <td className='pr-3 py-1'>
                                    <div className='flex'>
                                        <div className='w-1/2 mr-3'>{row.sugar.min}</div>
                                        <div className='w-1/2 mr-3'>{row.sugar.max}</div>
                                    </div>
                                </td>
                                <td className='pr-3 py-1'>
                                    <div className='flex'>
                                        <div className='w-1/2 mr-3'>{row.starch.min}</div>
                                        <div className='w-1/2 mr-3'>{row.starch.max}</div>
                                    </div>
                                </td>
                                <td className="pr-3 py-1 whitespace-nowrap">
                                    <CheckBox checked={row.displayOnReport} onChange={e => {
                                        setGrowths(arrayUpdatePartial(growths, index, {...row, displayOnReport: e}))
                                    }}/>
                                </td>
                                <td className='w-full'>
                                    <div className='flex justify-end ml-2 my-2'>
                                        <div className='btn-sm bg-primary-500' onClick={() => editGrowth(row)}>Edit
                                        </div>
                                        <div className='btn-sm bg-red-500' onClick={() => selectNewGrowth(row)}>x</div>
                                    </div>
                                </td>
                            </tr>)
                        }
                     </tbody>
                     <div className="btn-primary btn-sm m-2"
                          onClick={() => editGrowth(emptyCarboCropGrowthDisplay(firstTissueId))}>+
                     </div>
                 </table>
                     <div className="text-right p-2 border-t sticky bottom-0 bg-white">
                         <button className="btn btn-error" onClick={() => cancelGrowths()}>Cancel</button>
                         <div className="btn btn-primary" onClick={() => updateCrop()}>Update</div>
                     </div>
                 </div>}/>

            <Dialog title={growth.id ? "Edit Growth" : "New Growth"}
                    show={showEditGrowth}
                    setShow={setShowEditGrowth}
                    body={<div>
                        <EditTable discard={() => setShowEditGrowth(false)} save={() => upsertGrowth()}
                                   saveWord={growth.id ? 'Update' : 'Add'}>
                        {EditRow('Name', <Input value={growth.name ?? ''}
                                                change={v => setGrowth({...growth, name: v})}/>)}
                        {EditRow('Sugar (Min)', <Number value={growth.sugar.min} change={v => setGrowth({...growth, sugar: {...growth.sugar, min: v}})}/> )}
                        {EditRow('Sugar (Max)', <Number value={growth.sugar.max} change={v => setGrowth({...growth, sugar: {...growth.sugar, max: v}})}/> )}
                        {EditRow('Starch (Min)', <Number value={growth.starch.min} change={v => setGrowth({...growth, starch: {...growth.starch, min: v}})}/> )}
                        {EditRow('Starch (Max)', <Number value={growth.starch.max} change={v => setGrowth({...growth, starch: {...growth.starch, max: v}})}/> )}
                        {
                            growth.id 
                                ? null
                                : EditRow('Organ Types',  <SelectNumber options={tissues}
                                                                        textFunc={t => t.name}
                                                                        valueFunc={t => t.id}
                                                                        value={growth.linkedTissueId}
                                                                        onChange={(selectedLinkedId) => {
                                                                            setGrowth({...growth, linkedTissueId: selectedLinkedId})
                                                                        }}/>)
                        }
                    </EditTable>
                </div>}/>

            <CultivarsDialog
                availableGrowthStages={availableGrowthStages}
                cultivars={cultivars}
                setCultivars={setCultivars}
                selectedCrop={cultivarSelectedCrop}
                setSelectedCrop={setCultivarSelectedCrop}/>

            <RegionsDialog
                availableGrowthStages={availableGrowthStages}
                regions={regions}
                setRegions={setRegions}
                regionSelectedCrop={regionSelectedCrop}
                setRegionSelectedCrop={setRegionSelectedCrop}/>


            <Dialog title={`Assign ${selectedGrowth.name} to New Growth`}
                    show={assignGrowth}
                    setShow={() => cancelGrowthAssign()}
                    body={<div>
                        <EditTable discard={() => cancelGrowthAssign()} save={() => removeGrowth()}
                                   saveWord={'ReAssign'}>
                        {growths !== null && growths ? EditRow('Growth', <div className='text-right'><select
                            className="block border-2 w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                            value={newGrowthId}
                            onChange={v => setNewGrowthId(parseInt(v.target.value))}
                        >
                            <option key='null' disabled={true} value={0}>Click to select</option>
                            {
                                growths.filter(g => g.id !== selectedGrowth.id).map(grow => <option key={grow.id} value={grow.id}>{grow.name} ({grow.linkedTissueName})</option>)
                            }
                            </select></div>,
                            validation.rules.reassigned,
                            'Select a growth to reassign'
                        ) : null}
                    </EditTable>
                </div>}/>
        </div>
)
}
export default GrowthStages;
