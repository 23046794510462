import React, {Fragment, useRef, useState} from "react";
import {Tab} from "@headlessui/react";
import JobTable from "./JobTable";
import {classNames} from "../../wrapper";
import CreateJob, {JobStatus} from "./createjob";
import LabController from "../../controllers/LabController";

export interface JobTableFunctions {
    refresh: () => void;
}

function buttonClass(selected: boolean): string {
    return classNames('w-full rounded-lg text-3xl 2xl:text-sm p-4 2xl:p-0 font-medium leading-5 text-primary-700 ring-white ring-opacity-60 ring-offset-2 ring-offset-primary-400 focus:outline-none focus:ring-2', selected
        ? 'bg-white shadow'
        : 'text-primary-100 hover:bg-white/[0.12] hover:text-white');
}


const LabJobs: React.FC = () => {
    const jobTableRef = useRef<JobTableFunctions>()
    const [jobId, setJobId] = useState<JobStatus>('closed')

    function createJob() {
        setJobId('create')
    }

    function refreshJob() {
        jobTableRef.current?.refresh()
    }

    return (
        <div>
            <button
                onClick={createJob} className="btn btn-primary my-2">Create Job
            </button>
            <Tab.Group refName='componentRef' defaultIndex={-1}>
                <Tab.List className="flex space-x-1 rounded-xl bg-primary-500 p-1">
                    <Tab as={Fragment}>
                        {({selected}) => (
                            <button
                                className={buttonClass(selected)}
                            >
                                CURRENT
                            </button>
                        )}
                    </Tab>
                    <Tab as={Fragment}>
                        {({selected}) => (
                            <button
                                className={buttonClass(selected)}
                            >
                                COMPLETED
                            </button>
                        )}
                    </Tab>
                    <Tab as={Fragment}>
                        {({selected}) => (
                            <button
                                className={buttonClass(selected)}
                            >
                                DOWNLOADED
                            </button>
                        )}
                    </Tab>
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        <JobTable completed={false} archived={false} componentRef={jobTableRef} call={LabController.jobs}/>
                    </Tab.Panel>
                    <Tab.Panel>
                        <JobTable completed={true} archived={false} componentRef={jobTableRef} call={LabController.jobsCompleted}/>
                    </Tab.Panel>
                    <Tab.Panel>
                        <JobTable completed={true} archived={true} componentRef={jobTableRef} call={LabController.jobsArchived}/>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
            <CreateJob 
                jobId={jobId} 
                setModelValue={show => {
                   if (!show) {
                       refreshJob()
                       setJobId('closed')
                   }
                }} />
        </div>
    )
}

export default LabJobs
;
