import Papa from "papaparse";

export type CSVPopulate<T> = (value: string, entry: T) => void;

export type ColumnMatcher<T> = {
    populate: (value: string, entry: T) => void;
    match: (header: string) => boolean;
}

function parse<T>(text: string, factory: (index: number) => T, handler: (header: string, value: string, entry: T) => void) {
    const results = Papa.parse<string[]>(text, {header: false});
    // // Example (header: false)
    // [
    //     ["Column 1", "Column 2"],
    //     ["foo", "bar"],
    //     ["abc", "def"]
    // ]

    if (results.data.length <= 1) {
        return []
    }
    const headers = results.data[0]!;
    return results.data.slice(1).map((row, index) => {
        const entry = factory(index)

        for (let column = 0; column < row.length; column++) {
            const header = headers[column]
            if (!header) {
                continue
            }

            handler(header, row[column]!, entry);
        }
        return entry
    })
}

export function parseCSVFile<T>(text: string, factory: (index: number) => T, csvRecords: Record<string, CSVPopulate<T>> | ColumnMatcher<T>[]): T[] {
    if (Array.isArray(csvRecords)) {
        return parse(text, factory, (header, value, entry) => {
            for (const matcher of csvRecords) {
                if (matcher.match(header)) {
                    matcher.populate(value, entry)
                    break
                }
            }
        });
    }

    const columns = remap(csvRecords, key => key.toLowerCase().trim());
    return parse(text, factory, (header, value, entry) => {
        // search for header in csvRecords case insensitive and trimmed
        const fn = columns[header.toLowerCase().trim()];
        if (fn) {
            fn(value, entry)
        }
    });
}

function remap<T>(rec: Record<string, T>, keyMapper: (key: string) => string): Record<string, T> {
    const result: Record<string, T> = {}
    for (const [key, value] of Object.entries(rec)) {
        result[keyMapper(key)] = value
    }
    return result
}