/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type CarbohydrateTissueRequest from "./CarbohydrateTissueRequest";
import type CarbohydrateTissue from "./CarbohydrateTissue";
import type IdName from "./IdName";
import type IdRequest from "./IdRequest";
import type ActiveId from "./ActiveId";
import type PaginationRequestSearch from "./PaginationRequestSearch";

const urls = {
    index(): string {
        return "/api/carbohydratetissue/index";
    },
    getActiveTissues(): string {
        return "/api/carbohydratetissue/getactivetissues";
    }
}

export default {
    urls,
    upsert(request: CarbohydrateTissueRequest): Promise<AxiosResponse> {
        return Axios.post("/api/carbohydratetissue/upsert", request, defaultConfig);
    },
    index(): Promise<AxiosResponse<CarbohydrateTissue[]>> {
        return Axios.get("/api/carbohydratetissue/index", defaultConfig);
    },
    getActiveTissues(): Promise<AxiosResponse<IdName[]>> {
        return Axios.get("/api/carbohydratetissue/getactivetissues", defaultConfig);
    },
    delete(request: IdRequest): Promise<AxiosResponse> {
        return Axios.post("/api/carbohydratetissue/delete", request, defaultConfig);
    },
    tissueActive(request: ActiveId): Promise<AxiosResponse> {
        return Axios.post("/api/carbohydratetissue/tissueactive", request, defaultConfig);
    },
    paged(request: PaginationRequestSearch): Promise<AxiosResponse> {
        return Axios.post("/api/carbohydratetissue/paged", request, defaultConfig);
    }
}
