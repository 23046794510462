import React, { createContext, ReactNode } from 'react'
import PermissionEnum from './controllers/PermissionEnum'
import WindowInitial from "./controllers/WindowInitial";

export interface DialogData {
    title: string,
    body: React.ReactNode,
    onClose?: () => void,
    mounted?: (el: HTMLDivElement) => void;
}

export interface AppContextData {
    readonly isLoading: boolean,
    // return callback to hide the loader again
    showLoader: () => () => void,
    readonly initial: Readonly<WindowInitial>;
    
    showSnack (node: ReactNode, timeout?: number): () => void;

    hasPermission(permission: PermissionEnum): boolean;
}

// This is the default context required by react.
export default createContext<AppContextData>({
    hasPermission (permission: PermissionEnum): boolean {
        return false
    },
    isLoading: false,
    showLoader () {
        return () => {
        }
    },

    initial: {
        authenticated: false,
        availablePermissions: {},
        name: '',
        updating: false,
        version: '',
        languages: [],
        permissions: [],
        isDev: false,
        settings: {
            sugarLimit: 0,
            starchLimit: 0
        }
    },
    showSnack () {
        return () => {
        }
    }
})
