import React, {useContext, useEffect, useRef, useState} from "react";
import Dialog from "../../components/Dialog";
import CargoCropDisplay from "../../controllers/CargoCropDisplay";
import GrowthsTable, {GrowthsTableProps} from "./GrowthsTable";
import AppContext from "../../appContext";
import IdName from "../../controllers/IdName";
import CultivarGrowthDetails from "../../controllers/CultivarGrowthDetails";
import CarbohydrateController from "../../controllers/CarbohydrateController";
import TestCarboCropGrowth from "../../controllers/TestCarboCropGrowth";
import CarboCropGrowthDisplay from "../../controllers/CarboCropGrowthDisplay";

const CultivarsDialog: React.FC<{
    selectedCrop: CargoCropDisplay | null,
    setSelectedCrop: (v: CargoCropDisplay | null) => void,
    cultivars: CultivarGrowthDetails[],
    availableGrowthStages: CarboCropGrowthDisplay[],
    setCultivars: (v: CultivarGrowthDetails[]) => void, // use this when updating the cultivars
}> = (props) => {
    const context = useContext(AppContext)
    const [cultivars, setCultivars] = useState<CultivarGrowthDetails[]>(props.cultivars)

    //set Refs for children
    const growthsTableRefs = useRef<GrowthsTableProps[]>([]);

    useEffect(() => {
        setCultivars(props.cultivars)
    }, [props.cultivars])

    function updateRegions() {
        const hideLoader = context.showLoader()

        // getall children ref data
        const growthValues = growthsTableRefs.current.map(r => r.getData())
        const request: CultivarGrowthDetails[] = cultivars.map((r, index) => {
            return {
                cultivarId: r.cultivarId,
                cultivarName: r.cultivarName,
                growthValues: growthValues[index]! // must exists
            }
        });

        CarbohydrateController
            .updateCultivarGrowth(request)
            .then(resp => {
                props.setCultivars([])
                props.setSelectedCrop(null)
            })
            .finally(hideLoader)
    }

    return <Dialog title="Cultivars" show={props.selectedCrop != null} setShow={() => props.setSelectedCrop(null)}>
        {
            cultivars.map((cultivar, index) => <div key={cultivar.cultivarId} className='m-2 p-2 border' >
                <div className='flex'>
                    <span>{cultivar.cultivarName}</span>
                </div>
                <GrowthsTable ref={el => (growthsTableRefs.current[index] = el!)} 
                              key={index} availableGrowthStages={props.availableGrowthStages} 
                              growths={cultivar.growthValues}/>
            </div>)
        }

        <div className="text-right p-2 border-t sticky bottom-0 bg-white">
            <button className="btn btn-error" onClick={() => props.setSelectedCrop(null)}>
                Cancel
            </button>
            <div className="btn btn-primary" onClick={updateRegions}>
                Update
            </div>
        </div>
    </Dialog>
}

export default CultivarsDialog;
