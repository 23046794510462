import React, {useContext, useEffect, useState} from "react";
import AppContext from "../appContext";
import Input from "./Input";
import CropController from "../controllers/CropController";
import {CarbohydrateFilter} from "../pages/Carbohydrates";
import CropWithGrowthStages from "../controllers/CropWithGrowthStages";
import SelectNumberNullable from "./SelectNumberNullable";
import CarbohydrateTissueController from "../controllers/CarbohydrateTissueController";
import IdName from "../controllers/IdName";
import {XMarkIcon} from "@heroicons/react/24/solid";
import GrowthDisplay from "../controllers/GrowthDisplay";

function emptyCarbohydrateFilter(): CarbohydrateFilter {
    return {
        client: null,
        farmName: null,
        cropId: null,
        fieldBlockNumber: null,
        cropGrowthId: null,
        organ: null
    }
}

const CarbohydrateSearchFilter: React.FC<{
    show: boolean;
    onSearch: (filter: CarbohydrateFilter) => void;
    onClose: () => void;
    reset: () => void;
}> = (props) => {
    const context = useContext(AppContext)
    
    const [crops, setCrops] = useState<CropWithGrowthStages[]>([])
    const [organOptions, setOrganOptions] = useState<IdName[]>([])
    const [filter, setFilter] = useState<CarbohydrateFilter>(emptyCarbohydrateFilter())

    useEffect(() => {
        Promise.all([CropController.withGrowths(), CarbohydrateTissueController.getActiveTissues()])
            .then(([crops, activeTissues]) => {
                setCrops(crops.data)
                setOrganOptions(activeTissues.data)
            })
    }, [])
    
    function filterBy(field: Partial<CarbohydrateFilter>) {
        setFilter({
            ...filter,
            ...field
        })
    }

    function getOptionsForCrop(): GrowthDisplay[] {
        return crops.find(c => c.id === filter.cropId)?.growths || []
    }

    function getTissueName(linkedTissueId: number) {
        return organOptions.find(c => c.id === linkedTissueId)?.name ?? "none"
    }

    function growthStageText(growthStage: GrowthDisplay) {
        return growthStage.name + " (" + getTissueName(growthStage.linkedTissueId) + ")"
    }

    if (props.show) {
        return <div className="text-white p-4 shadow-md  absolute z-50 bg-white mt-1">

            <div className="text-right">
                <XMarkIcon className="cursor-pointer inline-block w-6 h-6 text-gray-400"
                       onClick={() => props.onClose()}/>
            </div>

            <table className="w-full text-gray-600">
                <tr>
                    <td className="pl-4 pr-8">Client</td>
                    <td className="py-1">
                        <Input value={filter.client ?? ""} change={v => {
                            filterBy({client: v})
                        }}/>
                    </td>
                </tr>
                <tr>
                    <td className="pl-4 pr-8">Farm Name</td>
                    <td className="py-1">
                        <Input value={filter.farmName ?? ""} change={v => filterBy({
                            farmName: v
                        })}/>
                    </td>
                </tr>
                <tr>
                    <td className="pl-4 pr-8">Field Block Number</td>
                    <td className="py-1">
                        <Input value={filter.fieldBlockNumber ?? ""} change={v => filterBy({
                            fieldBlockNumber: v
                        })}/>
                    </td>
                </tr>
                <tr>
                    <td className="pl-4 pr-8">Crop</td>
                    <td className="py-1">
                        <SelectNumberNullable<CropWithGrowthStages>
                            options={crops}
                            textFunc={c => c.name}
                            valueFunc={c => c.id}
                            value={filter.cropId}
                            onChange={(cropId) => {
                                filterBy({
                                    cropId: cropId,
                                    cropGrowthId: null
                                })
                            }}/>
                    </td>
                </tr>
                <tr>
                <td className="pl-4 pr-8">Growth Stage</td>
                    <td className="py-1">
                        <SelectNumberNullable
                            options={getOptionsForCrop()}
                            textFunc={c => growthStageText(c)}
                            valueFunc={c => c.id}
                            value={filter.cropGrowthId}
                            onChange={(cropGrowthId) => {
                                filterBy({
                                    cropGrowthId: cropGrowthId
                                })
                            }}/>
                    </td>
                </tr>
                <tr>
                    <td className="pl-4 pr-8">Organ</td>
                    <td className="py-1">
                        <SelectNumberNullable
                            options={organOptions}
                            valueFunc={c => c.id}
                            textFunc={c => c.name}
                            value={organOptions.find(c => c.name === filter.organ)?.id ?? null}
                            onChange={(organ) => {
                                filterBy({
                                    organ: organOptions.find(c => c.id === organ)?.name ?? null
                                })
                            }}/>
                    </td>
                </tr>
            </table>

            <div className="text-right mt-2">
                <div className="inline-block px-4 cursor-pointer text-gray-600" onClick={() => {
                    setFilter(emptyCarbohydrateFilter())
                    props.reset()
                }}>reset
                </div>
                <div className="btn bg-primary-500" onClick={() => props.onSearch(filter)}>search</div>
            </div>

        </div>
    }
    return <></>
}

export default CarbohydrateSearchFilter