import React, {useContext, useEffect, useState} from "react";
import Number from "../components/Number";
import {shallowEquality} from "../immutableState";
import SettingsController from "../controllers/SettingsController";
import {showSuccessOrFailed} from "../Snacks";
import AppContext from "../appContext";
import SettingsDisplay from "../controllers/SettingsDisplay";

const initialSettings: SettingsDisplay = {
    sugarLimit: 0,
    starchLimit: 0
};

const SettingsPage: React.FC = (props) => {
    const [settings, _setSettings] = useState<SettingsDisplay>(initialSettings);
    const [modified, setModified] = useState(false);
    const context = useContext(AppContext)
    
    function setSettings(partial: Partial<SettingsDisplay>) {
        const newSettings = {...settings, ...partial};
        _setSettings(newSettings)
        setModified(!shallowEquality(newSettings, initialSettings))
    }
    
    useEffect(() => {
        SettingsController.get().then(data => {
            _setSettings(data.data)
        })
    }, [])
    
    function save() {
        // Call to save actual settings
        showSuccessOrFailed(context, SettingsController.update(settings))
    }

    return (
        <div className="bg-lightgray-700 p-2 shadow my-3">

            <div className="flex items-center justify-between">
                <div className='flex items-center'>

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"/>
                    </svg>

                    <div className='text-lg ml-3'>Limits</div>
                </div>


            </div>

            <div className="flex">
                <div className="w-1/2 m-4">
                    <div className="font-bold text-sm text-gray-600">Sugar Max:</div>
                    <Number value={settings.sugarLimit}
                            change={v => setSettings({sugarLimit: v})}
                    />


                </div>

                <div className="w-1/2 m-4">
                    <div className="font-bold text-sm text-gray-600">Starch Max:</div>

                    <Number value={settings.starchLimit} change={v => setSettings({starchLimit: v})}/>

                </div>
            </div>


            <div className="text-right mr-2">

                <div className={modified ? 'btn bg-primary' : 'btn-disabled'} onClick={() => save()}>
                    Save
                </div>
            </div>


        </div>
    )
}

export default SettingsPage;
