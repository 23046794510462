import { useState } from 'react'

type ValidationRule = (() => boolean) | boolean;
export type ValidationRules = Record<string, ValidationRule>;

export type ValidatedRules<T> = { [K in keyof T]: boolean };

function runRule (rule: ValidationRule): boolean {
    if (typeof rule === 'boolean') { return rule }

    return rule()
}

export function stringRequired(...params: string[]): boolean {
    return params.every(s => s.trim().length > 0);
}

export function isNaturalNumber(...params: (number | null)[]): boolean {
    return params.every(s => s != null && s > 0)
}

function run<T> (rules: T, state: (rule: ValidationRule) => boolean): {rules: ValidatedRules<T>, passed: boolean} {
    const r: any = {}
    let passed = true
    for (const k in rules) {
        r[k] = state(rules[k] as any)
        if (!r[k]) { passed = false }
    }

    return { rules: r, passed }
}

export function useValidation<T extends ValidationRules> (rules: T): {
    validate: () => boolean,
    clear: () => void;
    rules: ValidatedRules<T>;
    dirtyPassed: boolean;
} {
    const [realTime, setRealTime] = useState(false)

    function validate (): boolean {
        setRealTime(true)
        return run(rules, runRule).passed
    }

    function clear (): void {
        setRealTime(false)
    }

    const validated = realTime
        ? run(rules, runRule)
        : run(rules, () => true)

    return {
        clear,
        validate,
        rules: validated.rules,
        dirtyPassed: validated.passed
    }
}