import React, {useContext, useRef, useState} from "react";
import PagedSearchTable, {PagedTableFunctions} from "../components/PagedSearchTable";
import AgronomistController from "../controllers/AgronomistController";
import FirestoreAgronomist from "../controllers/FirestoreAgronomist";
import WarningPopup, {useWarningState} from "../components/WarningPopup";
import {showSuccessOrFailed} from "../Snacks";
import AppContext from "../appContext";
import {EditRow, EditTable} from "../components/Fields";
import Input from "../components/Input";
import Dialog from "../components/Dialog";
import {useValidation} from "../validation";
import Originate from "../controllers/Originate";

const emptyFirestoreAgronomist: FirestoreAgronomist = {
    id: 0,
    name: '',
    archived: false,
    originate: Originate.International
}

const ManageAgronomists: React.FC = (props) => {
    const pagedTableRef = useRef<PagedTableFunctions<FirestoreAgronomist>>()
    const context = useContext(AppContext)
    const warningState = useWarningState<number>(0)
    
    function deleteAgronomist(id: number){
        showSuccessOrFailed(context, AgronomistController.archive({id: id})).then(() => {
            pagedTableRef.current?.refresh();
        })
    }

    return <div>
        <PagedSearchTable componentRef={pagedTableRef}
                          call={AgronomistController.paged}
                          columns={[
                              {
                                  header: 'Agronomist',
                                  key: 'agronomist',
                                  row: (row) => row.name
                              },
                              {
                                  header: <div className='text-right'>Actions</div>,
                                  key: 'actions',
                                  row: (row, index) => <div className='text-right'>
                                      <div className="bg-red-500 btn-sm"
                                           onClick={() => warningState.show(row.name === '' ? 'Are you sure you want to delete this agronomist?' : `Are you sure you want to delete ${row.name}?`, row.id)}>x
                                      </div>
                                  </div>
                              }
                          ]}/>
        <WarningPopup state={warningState} onYes={() => deleteAgronomist(warningState.state.data)}/>
    </div>
}

export default ManageAgronomists;