import React, {PropsWithChildren} from 'react'
import {classNames} from "../wrapper";

const CheckBox: React.FC<PropsWithChildren<{
    checked: boolean;
    onChange?: (state: boolean) => void;
    // wrap checkbox around label. Sometimes parent want to add its own label. Default to true
    renderLabel?: boolean
    className?: string
}>> = (props) => {
    function changeHandler (e: React.ChangeEvent<HTMLInputElement>) {
        props.onChange?.(e.target.checked)
    }

    const content = <div className="m-1 w-auto inline-block whitespace-nowrap">
        <div className="align-middle w-4 h-4 inline-block relative rounded border select-none bg-gray-200 border-gray-800 mr-1">
            <input className="opacity-0 w-0 h-0 absolute" checked={props.checked} type="checkbox" onChange={changeHandler}/>
            <div className="checkbox-mark absolute inset-0 rounded hover:bg-primary-200"/>
        </div>
        <div className="inline-block">
            {props.children}
        </div>
    </div>

    if (props.renderLabel !== false) {
        return <label className={classNames(props.className, "cursor-pointer")}>{content}</label>
    }

    return content
}

export default CheckBox
