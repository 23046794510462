import AppContext from "../../appContext";
import React, {useContext, useEffect, useRef, useState} from "react";
import PagedSearchTable, {PagedTableFunctions, TableColumn} from "../../components/PagedSearchTable";
import LanguageController from "../../controllers/LanguageController";
import LangResult from "../../controllers/LangResult";
import TextEntries from "../../controllers/TextEntries";
import {showSuccessOrFailed} from "../../Snacks";
import Language from "../../controllers/Language";
import KeysLookup from "../../controllers/KeysLookup";
import {arrayMerge, createArray} from "../../array";
import {useMountedPromise} from "../lab/JobTable";
import Dialog from "../../components/Dialog";
import {EditRow, EditTable} from "../../components/Fields";
import Input from "../../components/Input";
import {useValidation} from "../../validation";
import WarningPopup, {useWarningState} from "../../components/WarningPopup";

function emptyLanguage(): Language {
    return {
        id: 0,
        name: null
    }
}

const Keys: React.FC = () => {

    const {createPromise, mounted} = useMountedPromise(); 
    
    const context = useContext(AppContext);
    const pagedTableRef = useRef<PagedTableFunctions<LangResult>>()
    const [keyModal, setKeyModal] = useState<boolean>(false)
    const [languages, setLanguages] = useState<Language[]>([emptyLanguage()])
    const [newKey, setNewKey] = useState<string>('')
    const [data, setData] = useState<KeysLookup[]>([])
    const warningState = useWarningState<number>(0);



    useEffect(() => {
        createPromise(LanguageController.index()).then(resp => {
            setLanguages(resp.data)
        })
        createPromise(LanguageController.keysLookup()).then(resp => {
            setData(resp.data)
        })
    }, [])

    const validation = useValidation({
        name: () => uniqueName() ,
    });
    
    function deleteKey() {
        showSuccessOrFailed(context, LanguageController.deleteKey(warningState.state.data)).then(resp => {
            pagedTableRef.current?.refresh();
        })
    }

    function upsertKeyText(row: LangResult) {
        let texts = Object.keys(row.texts)
        let result: TextEntries[] = texts.map(t => ({
            langId: parseInt(t),
            text: row.texts[parseInt(t)] ?? ''
        }))

        showSuccessOrFailed(context, LanguageController.upsertKeyText({
            keyId: row.keyId,
            entries: result
        }), "Success").then(() => {
            pagedTableRef.current?.refresh();
        })
    }
    
    function addNewKey() {
        if (!validation.validate()) return;

        showSuccessOrFailed(context, LanguageController.addNewKey({key: newKey, frontend: false})).then(() => {
            setKeyModal(false)
            pagedTableRef.current?.refresh();
        }).finally(() => setNewKey(''))
    }
    
    function uniqueName() {
        const uniqueKey = !data.some(key => key.key === newKey);
        return newKey.length > 0 && uniqueKey;
    }
    
    function changeInput(v: string, row: LangResult, langId: number, index: number) {
        pagedTableRef.current?.updateRow((rowPred) => {
            return rowPred === row
        }, (row) => {
            row.texts[langId] = v
        })
    }
    
    return (
        <div>
            <div className="pb-2">
                <div className="btn bg-primary m-2" onClick={() => setKeyModal(true)}>
                    Add key
                </div>
            </div>
            <PagedSearchTable componentRef={pagedTableRef} call={LanguageController.keys} keyExtractor={u => u.keyId} columns={arrayMerge<TableColumn<LangResult>>([[
                {
                    header: 'Key',
                    key: 'key',
                    row: (row, index) => row.key
                }], 
                createArray<TableColumn<LangResult>>(languages.length, i => ({
                    header: languages[i]?.name,
                    key: languages[i]?.id,
                    row: (row, inx) => {
                        const langId = languages[i]?.id;
                        if (!langId)
                            return <div>N/A</div>
            
                        return <input className="input-sm" type="text"
                                      onKeyDown={e => e.key === "Enter" ? upsertKeyText(row) : null}
                                      onChange={v => changeInput(v.target.value, row, langId, inx)}
                                      value={row.texts[langId] ?? ''}></input>
                    }
                })),
                [
                    {
                        header: <div className='text-right'>Action</div>,
                        key: 'action',
                        row: (row) => <div className='text-right'>
                            <div className="btn-primary btn-sm" onClick={() => upsertKeyText(row)}>Update</div>
                            <div className="btn bg-red-500 btn-sm" onClick={() => warningState.show(`Are you sure you want to delete ${row.key}? `, row.keyId)}>x</div>
                        </div>
                    }
                ]
             ])}/>


            <Dialog title="Key" show={keyModal} setShow={setKeyModal} body={<div className="p-2">
                <EditTable discard={() => {
                    setNewKey('')
                    setKeyModal(false)
                }} save={() => addNewKey()}
                           saveWord={'add'}>
                    {EditRow('Name', <Input value={newKey}
                                            change={v => setNewKey(v)}/>, validation.rules.name, "Unique key required")}
                </EditTable>
            </div>}/>

            <WarningPopup state={warningState} onYes={deleteKey}/>
        </div>
    )
}

export default Keys;
