import React, {Fragment, useContext, useMemo, useRef, useState} from "react";
import AppContext from "../appContext";
import PagedSearchTable, {PagedTableFunctions} from "../components/PagedSearchTable";
import RoleController from "../controllers/RoleController";
import RoleDisplay from "../controllers/RoleDisplay";
import {classNames} from "../wrapper";
import {buildSetter} from "../immutableState";
import Dialog from "../components/Dialog";
import {EditTable} from "../components/Fields";
import {useValidation} from "../validation";
import Input from "../components/Input";
import {recordToArray} from "../array";
import CheckBox from "../components/CheckBox";
import PermissionEnum from "../controllers/PermissionEnum";
import {showSuccessOrFailed} from "../Snacks";
import WarningPopup, {useWarningState} from "../components/WarningPopup";


function emptyRole(): RoleDisplay {
    return {
        count: 0,
        id: 0,
        name: '',
        permissions: []
    }
}

const Roles: React.FC = () => {
    const context = useContext(AppContext);
    const [show, setShow] = useState(false)
    const pagedTableRef = useRef<PagedTableFunctions<RoleDisplay>>()
    const [data, _setData] = useState<RoleDisplay>(emptyRole())
    const [setData] = buildSetter(data, _setData)
    const warningState = useWarningState<number>(0)
    

    const validation = useValidation({
        name: () => data.name.length > 0
    });

    function editRole(row: RoleDisplay) {
        setShow(true);
        _setData(row);
    }

    function warning(row: RoleDisplay) {
        if (row.count === 0) {
            warningState.show('Are you sure you want to delete this row?', row.id)
            return
        }
        context.showSnack('Cannot delete a role that is assigned to a user.')
    }


    function newRole() {
        _setData(emptyRole);
        setShow(true);
    }
    
    function addPermission(permission: PermissionEnum) {
        setData({permissions: [...data.permissions, permission]})
    }
    
    function removePermission(permission: PermissionEnum) {
        setData({permissions: data.permissions.filter(p => p !== permission)})
    }
    
    function upsertRole() {
        if (!validation.validate()) return
        
        showSuccessOrFailed(context, RoleController.upsert({name: data.name, permissions: data.permissions, id: data.id})).then(resp => {
            setShow(false)
            pagedTableRef.current?.refresh();
        })
    }


    interface PermissionGroup {
        group: string;
        items: PermissionEnum[]
    }
    
    function deleteRow(id: number) {
        showSuccessOrFailed(context, RoleController.delete({id: id})).then(() => {
            warningState.hide();
            pagedTableRef.current?.refresh();
        })
    }

    const groups = useMemo(() => {
        return recordToArray(context.initial.availablePermissions, (key, items) => ({
            group: key,
            items: items
        }) as PermissionGroup);
    }, [])

    return (
        <div className="bg-white p-2 shadow">
            <div className="btn btn-primary mb-2" onClick={newRole}>+</div>

            <PagedSearchTable componentRef={pagedTableRef} call={RoleController.paged} keyExtractor={u => u.id}
                              columns={[
                                  {
                                      header: 'Name',
                                      key: 'name',
                                      row: (row, index) => row.name
                                  },
                                  {
                                      header: 'User Assigned',
                                      key: 'assigned',
                                      row: (row, index) => row.count
                                  },
                                  {
                                      header: <div className='text-right'>Actions</div>,
                                      key: 'actions',
                                      row: (row, index) => <div className="text-right">
                                          <div
                                              className={classNames('m-1 btn-sm', row.count === 0 ? 'btn-error' : 'bg-gray-400')}
                                              onClick={() => warning(row)}
                                          >
                                              delete
                                          </div>
                                          <div className="btn-primary btn-sm" onClick={() => editRole(row)}>edit</div>
                                      </div>
                                  },
                              ]}/>

            <Dialog title="Role Profile" show={show} setShow={setShow} body={<>

                <EditTable discard={() => {setShow(false)}} 
                           save={() => upsertRole()} saveWord={data.id ? "Update" : "Add"}>
                    <tr>
                        <td>Role</td>
                        <td><Input className={!validation.rules.name ? 'ring-2 ring-red-400' : ''} value={data.name} change={v => setData({name: v})}/></td>
                    </tr>

                    {groups.map(group => {

                        return <Fragment key={group.group}>
                            <tr>
                                <td colSpan={2}>
                                    <div className="border-0 rounded-t-lg my-2 border-primary">
                                        <div className="bg-primary-500 rounded-md text-white p-2">{group.group}</div>
                                    </div>
                                </td>
                            </tr>


                            {group.items.map(p =>
                                <tr key={p}>

                                    <td>{p}</td>
                                    <td>
                                        <CheckBox checked={data.permissions.includes(p)} onChange={v => v ? addPermission(PermissionEnum[p]) : removePermission(PermissionEnum[p])}></CheckBox>
                                    </td>
                                </tr>
                            )}
                        </Fragment>
                    })}
                </EditTable>
            </>}/>
            <WarningPopup state={warningState} onYes={() => deleteRow(warningState.state.data)}/>
        </div>
    )
}

export default Roles;