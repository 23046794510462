import FileUploader from "./FileUploader";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import Dialog from "./Dialog";
import File from "../controllers/File";
import SubmissionController from "../controllers/SubmissionController";
import AppContext from "../appContext";
import WarningPopup, {useWarningState} from "./WarningPopup";

const ManualFileUploader: React.FC<{
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>
    submissionId: number
}> = (props) => {
    const context = useContext(AppContext)
    const warningStateNewUploadedFiles = useWarningState<number>(0)
    const [newUploadedFiles, setNewUploadedFiles] = useState<globalThis.File[]>([]);
    
    const warningStateExistingFiles = useWarningState<number>(0)
    const [existingFiles, setExistingFiles] = useState<File[]>([])

    const [deletedFiles, setDeletedFiles] = useState<number[]>([])
    
    useEffect(() => {
        if (!props.show) return;
        
        // reload the data when the dialog
        const hideLoader = context.showLoader()
        SubmissionController
            .getDocs({id: props.submissionId})
            .then(resp => setExistingFiles(resp.data))
            .finally(hideLoader)
    }, [props.show]);
    
    function addFile(file: globalThis.File) {
        setNewUploadedFiles([...newUploadedFiles, file]);
    }

    function submit(){
        props.setShow(false)
        const hideLoader = context.showLoader()
        SubmissionController.uploadManualSubmissionFiles({
            submissionId: props.submissionId,
            deleteFiles: deletedFiles,
            insertFiles: newUploadedFiles
        })
            .then(() => setNewUploadedFiles([]))
            .finally(hideLoader);
    }
    
    function showExistingFiles() {
        return existingFiles.map((file, index) => <div className="flex items-center p-3 justify-between">
            <div className="pr-4">{file.name}</div>
            <span
                onClick={() => warningStateExistingFiles.show(`Are you sure you want to delete this file? ${file.name}`, file.id)}
                className="btn-error btn-sm">x</span>
        </div>)
    }
    
    function showNewUploadedFiles() {
        return newUploadedFiles.map((file, index) => <div className="flex items-center p-3 justify-between">
            <div className="pr-4">{file.name}</div>
            <span
                onClick={() => warningStateNewUploadedFiles.show(`Are you sure you want to delete this file? ${file.name}`, index)}
                className="btn-error btn-sm">x</span>
        </div>)
    }

    return <Dialog title="Upload manual submissions files"
                   show={props.show}
                   setShow={props.setShow}>
        {
            showExistingFiles()
        }

        {
            showNewUploadedFiles()
        }

        <div className="p-3">
            <FileUploader onChange={f => addFile(f)}>
            </FileUploader>
        </div>

        <div className="text-right p-2 border-t sticky bottom-0 bg-white">
            <div className="btn btn-primary" onClick={() => submit()}>
                Submit
            </div>
        </div>
        <WarningPopup state={warningStateNewUploadedFiles} onYes={() => {
            setNewUploadedFiles(newUploadedFiles.filter((_, i) => i !== warningStateNewUploadedFiles.state.data))
        }}/>
        <WarningPopup state={warningStateExistingFiles} onYes={() => {
            setDeletedFiles([...deletedFiles, warningStateExistingFiles.state.data])
            setExistingFiles(existingFiles.filter(f => f.id !== warningStateExistingFiles.state.data))
        }}/>
    </Dialog>
}

export default ManualFileUploader;