import Axios, { type AxiosRequestConfig } from "axios";
import {isPrimitive} from "util";

export function isLocalhost() {
    return window.location.host.includes('localhost');
}

export function inRange(index: number, arr: any[]): boolean {
    return index >= 0 && index < arr.length;
}

function queryEncode(key: string, value: any) {
    return encodeURIComponent(key) + '=' + encodeURIComponent(value);
}

function keyValue(key: string, value: any): string {
    if (Array.isArray(value)) {
        return value.map(v => queryEncode(key, v)).join("&")
    }
    return queryEncode(key, value);
}

export function queryUrl(url: string, params: { [param: string]: any }): string {
    return url + "?" + Object.keys(params).map((key) => keyValue(key, params[key])).join('&');
}

function addItem(formData: FormData, key: string, value: object) {
    if (value instanceof File) {
        formData.append(key, value)
        return;
    }

    if (typeof value == 'object') {
        formData.append(key,  JSON.stringify(value))
        return;
    }
    
    if (value !== undefined) {
        formData.append(key, value)
    }
}

export function toFormData(obj: any): FormData {
    const formData = new FormData()
    for (const k in obj) {
        const v = obj[k];
        
        // only 1 level deep
        // array share the same name with multiple entries
        if (Array.isArray(v)) {
            for (const item of v) {
                addItem(formData, k, item);
            }
            continue;
        }
        
        addItem(formData, k, v);
    }
    return formData;
}

export function isRealNumber(number: number): boolean {
    return Number.isNaN(number) || !Number.isFinite(number);
}

export function asRealNumber(number: number): number {
    return isRealNumber(number) ? 0 : number;
}

export function asOptionalRealNumber(number: string): number | null {
    const p = parseFloat(number);
    return isRealNumber(p) ? null : p;
}

export function parseIntSafe(value: string): number {
    return asRealNumber(parseInt(value))
}

// Date format in ISO 8601 format
const reISO = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i

function parseDate(key: any, value: any): any {
    if (typeof value === 'string') {
        let a = reISO.exec(value);
        if (a)
            return new Date(value);
    }
    return value;
}

function transformDates(data: any): any {
    if (data == '' || data === null || data === undefined)
        return data

    try {
        // data may be invalid or not even be JSON
        return JSON.parse(data, parseDate);
    } catch {
        return data
    }
}

function transformRequest(data: any): any {
    return JSON.stringify(data, (key, value) => {
        // Infinity and NaN serialize as null
        // Now serialize as 0
        if (typeof value === 'number' && (isNaN(value) || !isFinite(value)))
            return 0;

        return value;
    });
}

export const defaultFormConfig: AxiosRequestConfig = {
    transformResponse: transformDates,
    headers: {'Content-Type': 'multipart/form-data'}
};

export const defaultConfig: AxiosRequestConfig = {
    transformResponse: transformDates,
    transformRequest: transformRequest,
    headers: {'Content-Type': 'application/json'}
};