import React from 'react'

export const SelectString: React.FC<{
    value: string;
    onChange: (value: string) => void;
    options: Record<string, string>;
}> = (props) => {
    const entries = Object.entries(props.options)
    return <select value={props.value} className="input"
        onChange={event => props.onChange(event.target.value)}>
        {entries.map(([text, value]) => <option key={value} value={value}>{text}</option>)}
    </select>
}

export const SelectStringNullable: React.FC<{
    value: string | null;
    onChange: (value: string | null) => void;
    options: Record<string, string>;
}> = (props) => {
    const entries = Object.entries(props.options)
    return <select value={props.value ? props.value : ''} className="input"
        onChange={event => props.onChange(!event.target.value ? event.target.value : null)}>
        {entries.map(([text, value]) => <option key={value} value={value}>{text}</option>)}
    </select>
}
