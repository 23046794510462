import React, {InputHTMLAttributes} from 'react'
import {classNames} from "../wrapper";

const Input: React.FC<{
    value: string;
    change: (value: string) => void;
    className?: string;
    children?: (attrs: InputHTMLAttributes<any>) => React.ReactNode
    focus?: boolean
    onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}> = (props) => {
    function onChange(e: React.FormEvent<HTMLInputElement>) {
        props.change((e.target as HTMLInputElement).value)
    }

    if (props.children) {
        return <>
            {props.children({
                value: props.value,
                onInput: onChange
            })}
        </>
    }

    return <input disabled={!!props.disabled} autoFocus={props.focus} type="text" className={classNames('input ', props.className, !!props.disabled ? 'bg-gray-200' : '')} onPaste={props.onPaste} value={props.value} onInput={onChange}/>
}

export default Input
