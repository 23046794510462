import React, {useRef} from "react";
import PagedSearchTable, {PagedTableFunctions} from "../components/PagedSearchTable";
import FarmController from "../controllers/FarmController";
import FarmManagementResponse from "../controllers/FarmManagementResponse";

const ManageFarms: React.FC = (props) => {
    const pagedTableRef = useRef<PagedTableFunctions<FarmManagementResponse>>()

    
    return <div>
        <PagedSearchTable componentRef={pagedTableRef}
                          call={FarmController.paged}
                          columns={[
                              {
                                  header: 'Client',
                                  key: 'client',
                                  row: (row) => row.client
                              },
                              {
                                  header: 'Farm',
                                  key: 'farm',
                                  row: (row) => row.farm
                              },
                              {
                                  header: 'MFW Id',
                                  key: 'mfwId',
                                  row: (row) => row.mfwId
                              },
                              {
                                  header: 'Originate',
                                  key: 'originate',
                                  row: (row) => row.originate
                              },
                          ]}/>
    </div>
}

export default ManageFarms;