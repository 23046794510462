import UserLoginCode from "../controllers/UserLoginCode";
import React, {useContext, useState} from "react";
import AuthController from "../controllers/AuthController";
import {useSearchParams} from "react-router-dom";
import {useValidation} from "../validation";
import AppContext from "../appContext";
import {bind, classNames, onEnter} from "../wrapper";
import Forgot from "./Forget";

const states: Record<UserLoginCode, string> = {
    Success: '',
    DoesNotExists: 'Account does not exists',
    Archived: 'Your account has been archived',
    InActive: 'Your account is inactive',
    Locked: 'Your account is locked',
    WrongPassword: 'Wrong email or password'
};

const Login: React.FC = () => {
    const [queryParams] = useSearchParams()

    const context = useContext(AppContext);
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [password, setPassword] = useState('');
    const [forgotShow, setForgotShow] = useState(false)

    const validation = useValidation({
        emailRequired: () => email.length > 0,
        passwordRequired: () => password.length > 0
    })

    function login() {
        if (!validation.validate()) return

        const hideLoader = context.showLoader();
        AuthController.login({email, password})
            .then(resp => {
                if (resp.data === UserLoginCode.Success) {
                    const returnUrl = queryParams.get('ReturnUrl')
                    window.location.href = returnUrl ?? '/';
                    return
                }
                setError(states[resp.data]);
            })
            .finally(() => {
                hideLoader()
            })
    }

    return (
        <>
            <div className="fixed right-0 p-1 text-xs">v {context.initial.version}</div>
            <div
                style={{
                    backgroundImage: 'url(/images/banner.jpg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top left',
                    backgroundSize: 'cover'
                }}
                className="min-h-screen flex items-center right-0 bg-gray-50 py-12 px-4 sm:px-6 lg:px-32 flex-row-reverse">

                <div className="max-w-md w-full space-y-8 bg-white p-2 rounded-t-lg">
                    <div id='portal'></div>
                    <div id="app">
                        <div>
                            <div>
                                <img className="mx-auto h-32 w-auto mt-2" src="/images/logo.png" alt="Workflow"/>
                                <h2 className="mt-6 text-center text-3xl text-primary-800">
                                    Sign in to your account
                                </h2>
                            </div>

                            <div className="mt-8 space-y-6">

                                <input type="hidden" name="remember" value="true"/>
                                <div className="rounded-md shadow-sm">
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <label htmlFor="email-address" className="sr-only">Email address</label>
                                        <input id="email-address" name="email" type="email" required
                                               className={classNames("appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:border-primary-500  sm:text-sm", !validation.rules.emailRequired ? 'ring-2 ring-red-400' : '')}
                                               autoFocus
                                               placeholder="Email address"
                                               {...bind(email, setEmail)}
                                               onKeyUp={onEnter(login)}
                                        />

                                        {!validation.rules.emailRequired ?
                                            <div
                                                className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                <svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg"
                                                     viewBox="0 0 20 20"
                                                     fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd"
                                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                                          clipRule="evenodd"/>
                                                </svg>
                                            </div>
                                            : null}

                                    </div>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <label htmlFor="password" className="sr-only">Password</label>
                                        <input id="password" name="password" type="password" required
                                               className={classNames("appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:border-primary-500  sm:text-sm", !validation.rules.passwordRequired ? 'ring-2 ring-red-400' : '')}
                                               placeholder="Password"
                                               {...bind(password, setPassword)}
                                               onKeyUp={onEnter(login)}
                                        />
                                        {!validation.rules.passwordRequired ?
                                            <div
                                                className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                <svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg"
                                                     viewBox="0 0 20 20"
                                                     fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd"
                                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                                          clipRule="evenodd"/>
                                                </svg>
                                            </div>
                                            : null}
                                    </div>
                                </div>

                                {error.length > 0 ? <div
                                    className="border border-red-600 text-red-800 p-1 rounded-sm">{error}</div> : null}

                                <div className="flex items-center flex-row-reverse justify-between">
                                    <div
                                        className="cursor-pointer text-sm font-medium text-primary-600 hover:text-primary-500" onClick={_ => setForgotShow(true)}>
                                        Forgot your password?
                                    </div>
                                    <Forgot setShow={setForgotShow} show={forgotShow}></Forgot>
                                </div>

                                <button type="submit" onClick={login}
                                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                        <svg className="h-5 w-5 text-primary-500 group-hover:text-primary-400"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd"
                              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                              clipRule="evenodd"/>
                    </svg>
                </span>
                                    Sign in
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;
