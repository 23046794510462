import React from "react";

const CustomSnack: React.FC<{
    svg?: React.ReactNode;
    title?: string;
    text?: string;
}> = (props) => {
    return (
        <div className="flex w-full items-center">
            {props.svg}
            <div className="px-1 ">
                <p className="font-medium text-gray-900 m-3">{props.title ?? 'done'}</p>
                <p className="mt-1 text-gray-500 m-3">{props.text}</p>
            </div>
        </div>
    )
}

export default CustomSnack