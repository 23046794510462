function size(upper: number | any[]): number {
    if (typeof upper === 'number')
        return upper - 1;

    if (Array.isArray(upper))
        return upper.length - 1;

    return 0;
}

export function dec(index: number, upper: number | any[]): number {
    if (index > 0)
        return index - 1;
    return size(upper);
}

export function outOfRange(index: number, upper: number | any[]): boolean {
    return index < 0 || index > size(upper);
}

export function inc(index: number, upper: number | any[]) {
    if (index < size(upper))
        return index + 1;
    return 0;
}