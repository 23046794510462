/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type PaginationResponse from "./PaginationResponse";
import type CarboResultsDisplayed from "./CarboResultsDisplayed";
import type PaginationGroupedRequestSearch from "./PaginationGroupedRequestSearch";
import type TestCarbohydrateLabResult from "./TestCarbohydrateLabResult";
import type IdRequest from "./IdRequest";
import type PaginationGroupedLocationRequestSearch from "./PaginationGroupedLocationRequestSearch";
import type MergeEntriesRequest from "./MergeEntriesRequest";
import type GetApiDataResponse from "./GetApiDataResponse";
import type FileContentResult from "./FileContentResult";
import type CarboReportRequest from "./CarboReportRequest";
import type CarboCropGrowthDisplay from "./CarboCropGrowthDisplay";
import type CultivarGrowthResponse from "./CultivarGrowthResponse";
import type TestCarboCropGrowth from "./TestCarboCropGrowth";
import type RegionResponse from "./RegionResponse";
import type CargoCropDisplay from "./CargoCropDisplay";
import type PaginationRequestSearch from "./PaginationRequestSearch";
import type CultivarGrowthDetails from "./CultivarGrowthDetails";
import type RegionDetails from "./RegionDetails";
import type AddRegionRequest from "./AddRegionRequest";
import type CropGrowthUpsert from "./CropGrowthUpsert";
import type ActiveId from "./ActiveId";
import type ReAssignRequest from "./ReAssignRequest";

const urls = {
    pagedExcel(request: PaginationGroupedRequestSearch): string {
        return queryUrl("/api/carbohydrate/pagedexcel", request);
    },
    pagedExcelGroupedLocation(request: PaginationGroupedLocationRequestSearch): string {
        return queryUrl("/api/carbohydrate/pagedexcelgroupedlocation", request);
    },
    pagedExcelIds(ids: number[]): string {
        return queryUrl("/api/carbohydrate/pagedexcelids", {ids});
    },
    blockReport(request: CarboReportRequest): string {
        return queryUrl("/api/carbohydrate/blockreport", request);
    }
}

export default {
    urls,
    paged(request: PaginationGroupedRequestSearch): Promise<AxiosResponse<PaginationResponse<CarboResultsDisplayed>>> {
        return Axios.post("/api/carbohydrate/paged", request, defaultConfig);
    },
    getLabResult(request: IdRequest): Promise<AxiosResponse<TestCarbohydrateLabResult>> {
        return Axios.post("/api/carbohydrate/getlabresult", request, defaultConfig);
    },
    pagedGroupedLocation(request: PaginationGroupedLocationRequestSearch): Promise<AxiosResponse<PaginationResponse<CarboResultsDisplayed>>> {
        return Axios.post("/api/carbohydrate/pagedgroupedlocation", request, defaultConfig);
    },
    mergeEntries(request: MergeEntriesRequest): Promise<AxiosResponse<boolean>> {
        return Axios.post("/api/carbohydrate/mergeentries", request, defaultConfig);
    },
    getApiData(request: IdRequest): Promise<AxiosResponse<GetApiDataResponse>> {
        return Axios.post("/api/carbohydrate/getapidata", request, defaultConfig);
    },
    pagedExcel(request: PaginationGroupedRequestSearch): Promise<AxiosResponse<FileContentResult>> {
        return Axios.get(queryUrl("/api/carbohydrate/pagedexcel", request), defaultConfig);
    },
    pagedExcelGroupedLocation(request: PaginationGroupedLocationRequestSearch): Promise<AxiosResponse<FileContentResult>> {
        return Axios.get(queryUrl("/api/carbohydrate/pagedexcelgroupedlocation", request), defaultConfig);
    },
    pagedExcelIds(ids: number[]): Promise<AxiosResponse<FileContentResult>> {
        return Axios.get(queryUrl("/api/carbohydrate/pagedexcelids", {ids}), defaultConfig);
    },
    blockReport(request: CarboReportRequest): Promise<AxiosResponse> {
        return Axios.get(queryUrl("/api/carbohydrate/blockreport", request), defaultConfig);
    },
    cropGrowths(request: IdRequest): Promise<AxiosResponse<CarboCropGrowthDisplay[]>> {
        return Axios.post("/api/carbohydrate/cropgrowths", request, defaultConfig);
    },
    cultivarGrowth(request: IdRequest): Promise<AxiosResponse<CultivarGrowthResponse>> {
        return Axios.post("/api/carbohydrate/cultivargrowth", request, defaultConfig);
    },
    regionsCropGrowths(request: IdRequest): Promise<AxiosResponse<TestCarboCropGrowth[]>> {
        return Axios.post("/api/carbohydrate/regionscropgrowths", request, defaultConfig);
    },
    getRegions(request: IdRequest): Promise<AxiosResponse<RegionResponse>> {
        return Axios.post("/api/carbohydrate/getregions", request, defaultConfig);
    },
    crops(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<CargoCropDisplay>>> {
        return Axios.post("/api/carbohydrate/crops", request, defaultConfig);
    },
    updateCultivarGrowth(cultivarGrowths: CultivarGrowthDetails[]): Promise<AxiosResponse> {
        return Axios.post("/api/carbohydrate/updatecultivargrowth", cultivarGrowths, defaultConfig);
    },
    updateRegions(regions: RegionDetails[]): Promise<AxiosResponse> {
        return Axios.post("/api/carbohydrate/updateregions", regions, defaultConfig);
    },
    deleteRegion(request: IdRequest): Promise<AxiosResponse> {
        return Axios.post("/api/carbohydrate/deleteregion", request, defaultConfig);
    },
    addRegion(request: AddRegionRequest): Promise<AxiosResponse<number>> {
        return Axios.post("/api/carbohydrate/addregion", request, defaultConfig);
    },
    cropGrowthsUpsert(request: CropGrowthUpsert): Promise<AxiosResponse<CarboCropGrowthDisplay[]>> {
        return Axios.post("/api/carbohydrate/cropgrowthsupsert", request, defaultConfig);
    },
    updateCropActive(request: ActiveId): Promise<AxiosResponse> {
        return Axios.post("/api/carbohydrate/updatecropactive", request, defaultConfig);
    },
    cropGrowthsRemove(request: ReAssignRequest): Promise<AxiosResponse> {
        return Axios.post("/api/carbohydrate/cropgrowthsremove", request, defaultConfig);
    }
}
