/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type LoggedInUser from "./LoggedInUser";
import type UserResponse from "./UserResponse";
import type ExistRequest from "./ExistRequest";
import type EmailId from "./EmailId";
import type PaginationResponse from "./PaginationResponse";
import type UserDisplay from "./UserDisplay";
import type PaginationRequestSearch from "./PaginationRequestSearch";
import type UserRequest from "./UserRequest";
import type QuickUpdateRequest from "./QuickUpdateRequest";

const urls = {
    index(): string {
        return "/api/user/index";
    }
}

export default {
    urls,
    index(): Promise<AxiosResponse<LoggedInUser>> {
        return Axios.get("/api/user/index", defaultConfig);
    },
    unique(request: ExistRequest): Promise<AxiosResponse<UserResponse>> {
        return Axios.post("/api/user/unique", request, defaultConfig);
    },
    uniqueEmail(request: EmailId): Promise<AxiosResponse<boolean>> {
        return Axios.post("/api/user/uniqueemail", request, defaultConfig);
    },
    paged(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<UserDisplay>>> {
        return Axios.post("/api/user/paged", request, defaultConfig);
    },
    upsert(request: UserRequest): Promise<AxiosResponse> {
        return Axios.post("/api/user/upsert", request, defaultConfig);
    },
    quickUpdate(request: QuickUpdateRequest): Promise<AxiosResponse> {
        return Axios.post("/api/user/quickupdate", request, defaultConfig);
    }
}
