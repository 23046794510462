import React, {useContext, useEffect, useRef, useState} from "react";
import AppContext from "../../appContext";
import Graphs from "./Graphs";
import {CultivarRef} from "./Cultivars";
import CropGraphs from "../../controllers/CropGraphs";
import ImageUploader from "../../components/ImageUploader";
import {wrapLoader} from "../../wrapper";
import MappedDrisGraph from "../../controllers/MappedDrisGraph";
import {showSuccessOrFailed} from "../../Snacks";
import Input from "../../components/Input";
import FirestoreCrop from "../../controllers/FirestoreCrop";
import CropController from "../../controllers/CropController";
import {arrayRemoveIndex, arrayUpdate} from "../../immutableState";
import WarningPopup, {useWarningState} from "../../components/WarningPopup";

interface CropForm extends CropGraphs {
    image: File | null;
    newImage: boolean;
}

const Crop: React.FC<{
    cropId: number;
    close: (saved: boolean) => void;
}> = (props) => {
    const context = useContext(AppContext);
    const [data, setData] = useState<CropForm | null>(null)

    const graphRef = useRef<CultivarRef | null>(null);
    const [crops, setCrops] = useState<FirestoreCrop[]>([])
    const [linkedCropNames, setLinkedCropNames] = useState<string[]>([])
    const warningState = useWarningState<number>(-1)



    useEffect(() => {
        CropController.all().then(resp =>{
            setCrops(resp.data)
            setLinkedCropNames(resp.data.find(c => c.id === props.cropId)?.linkedNames ?? linkedCropNames)
        })
        
        wrapLoader(context,CropController.get(props.cropId), d => {
            setData({
                name: d.name,
                cultivarId: d.cultivarId,
                drisGraphs: d.drisGraphs,
                imageHash: d.imageHash,
                imagePath: d.imagePath,
                macroGraph: d.macroGraph,
                macroGraphSelected: d.macroGraphSelected,
                microGraph: d.microGraph,
                microGraphSelected: d.microGraphSelected,
                molybdenumGraph: d.molybdenumGraph,
                molybdenumGraphSelected: d.molybdenumGraphSelected,
                nickelGraph: d.nickelGraph,
                nickelGraphSelected: d.nickelGraphSelected,
                nitrateGraph: d.nitrateGraph,
                nitrateGraphSelected: d.nitrateGraphSelected,
                ammoniumGraph: d.ammoniumGraph,
                ammoniumGraphSelected: d.ammoniumGraphSelected,
                image: null,
                newImage: false
            });
        })
    }, [])
    
    function update() {
        if (graphRef.current == null)
            return

        const update = graphRef.current.requestUpdate();
        showSuccessOrFailed(context, CropController.upsert({
            data: {
                drisGraphs: update.drisGraphs
                    .filter(g => g.selected)
                    .map<MappedDrisGraph>(g => ({
                        values: g.rows,
                        drisGraphId: g.id,
                    })),
                linkedCrops: linkedCropNames,
                macroGraph: update.macroGraphSelected ? update.macroGraph : null,
                microGraph: update.microGraphSelected ? update.microGraph : null,
                molybdenumGraph: update.molybdenumGraphSelected ? update.molybdenumGraph : null,
                nickelGraph: update.nickelGraphSelected ? update.nickelGraph : null,
                nitrateGraph: update.nitrateGraphSelected ? update.nitrateGraph : null,
                ammoniumGraph: update.ammoniumGraphSelected ? update.ammoniumGraph : null,
                newImage: data!.newImage
            },
            id: props.cropId,
            image: data!.image,
        })).then(() => {
            props.close(true)
        })
    }

    function backgroundUrl(): string {
        // No image uploaded yet
        if (data == null || data.imagePath.length == 0)
            return '/images/noimage.jpg'

        return `/upload/crops/${data.imagePath}-low.png?${data.imageHash}`
    }

    function setNewFileImage(file: File): void {
        setData({...data!, image: file, newImage: true});
    }
    
    function editName(c: string, index: number) {
        setLinkedCropNames(arrayUpdate(linkedCropNames, index, c))
    }

    function deleteLinkedName(index: number) {
        setLinkedCropNames(arrayRemoveIndex(linkedCropNames, index))
    }


    if (data == null)
        return <div className="absolute inset-0 bg-overlay-200 flex justify-center items-center">
            <img src='/images/loader.gif' alt=''/>
        </div>
    
    return <>
        <div className='text-center'>
            <div className="py-2">
                <ImageUploader imageUrl={backgroundUrl()} setImage={setNewFileImage}/>
            </div>
            {
                linkedCropNames.length > 0 ? <div className='w-full bg-gray-100'>
                <div className='text-lg py-2 font-bold'>
                    Alternative names for {data.name}:
                </div>
                <span className='flex flex-wrap pb-2 justify-center'>
                    {linkedCropNames.map((c, index) => <div className='flex w-[27%]'>
                        <Input value={c ?? ''} change={c => editName(c, index)}/>
                        <button className="m-1 btn-error btn-sm"
                                onClick={_ => warningState.show(`Are you sure you want to delete ${c} as an alternative name for ${data?.name}?`, index)}>x
                        </button>
                    </div>)}
                </span>
            </div> : null
            }
        </div>
        
        <Graphs graphs={data} ref={graphRef} showDrisGraphs={true}/>
        

        <div className="text-right p-2 border-t sticky bottom-0 bg-white">

            <button className="btn btn-error" onClick={() => props.close(false)}>
                Cancel
            </button>
            <div className="btn btn-primary" onClick={() => update()}>
                Update
            </div>
        </div>
        
        <WarningPopup state={warningState} onYes={() => deleteLinkedName(warningState.state.data)}/>

    </>
}

export default Crop;