import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import AppContext from "../../appContext";
import LabController from "../../controllers/LabController";
import {wrapLoader} from "../../wrapper";
import Dialog from "../../components/Dialog";
import {arrayUpdatePartial, buildSetter} from "../../immutableState";
import TestCarboJobInput from "../../controllers/TestCarboJobInput";
import NumberNullable from "../../components/NumberNullable";
import CheckBox from "../../components/CheckBox";
import {showSuccessOrFailed} from "../../Snacks";
import SettingsDisplay from "../../controllers/SettingsDisplay";
import SettingsController from "../../controllers/SettingsController";
import {inputStyling} from "../../styles";
import WarningPopup, {useWarningState} from "../../components/WarningPopup";
import CarbohydrateController from "../../controllers/CarbohydrateController";
import Success from "../../components/Success";


const ViewJob: React.FC<{
    id: number
    refresh: () => void
    setId: (value: number) => void 
    completed: boolean
}> = (props) => {
    const context = useContext(AppContext);
    const tableRef = useRef<HTMLTableElement>(null);
    const [carbos, setCarbos] = useState<TestCarboJobInput[]>([]);
    const [buildCarbos] = buildSetter(carbos, setCarbos);
    const warningState = useWarningState<number>(0);
    const [deletedCarbosIds, setDeletedCarbosIds] = useState<number[]>([])
    
    const [settings, setSettings] = useState<SettingsDisplay | null>(null);
    
    const [nextRowDate, setNextRowData] = useState(-1)

    useEffect(() => {
        wrapLoader(context, LabController.jobData({id: props.id}), resp => {
            setCarbos(resp.testCarbohydrateLabResults)
            setNextRowData(0)
            focusTo(0,0)
        })
    }, [props.id])

    function cancel() {
        props.refresh()
        props.setId(0)
    }

    function updateResult() {
        if (deletedCarbosIds.length > 0) {
           LabController.removeResult(deletedCarbosIds).then(() => setDeletedCarbosIds([]))
        }
        showSuccessOrFailed(context, LabController.updateResults({entries: carbos})).then(() => {
            cancel()
            props.refresh()
        })
    }
    
    function exportJob() {
        window.location.href = CarbohydrateController.urls.pagedExcelIds(carbos.map(carb => carb.id));
        LabController.archiveJob(carbos.map(c => ({id: c.id})))
        context.showSnack(<Success title={'Starting download'}/>)
    }

    function focusTo(column: number, index: number) {
        (tableRef.current
            ?.querySelector('tbody')
            ?.querySelectorAll('tr')[index]
            ?.querySelectorAll('input, select')[column] as HTMLInputElement)?.focus()
    }
    
    function keyDown(key: string, column: number, index: number) {
        const nextIndex = carbos.findIndex((c, i) => i > index  && !c.na)
        const carb = carbos[index]
        if (key === "Enter" ) {
            nextIndex !== -1
                ? focusTo(column, nextIndex) 
                : focusTo(column === 0 ? 1 : 0, carbos.findIndex(c => !c.na))
            
            if (!carb) return
            LabController.updateResult({
                id: carb.id,
                sugar: carb.sugar,
                starch: carb.starch,
                na: carb.na
            })
        }
    }
    
    function anySugar() {
        return carbos.some(item => (item.sugar ?? 0) > settings!.sugarLimit);
    }

    function anyStarch() {
        return carbos.some(item => (item.starch ?? 0) > settings!.starchLimit);
    }

    useEffect(() => {
        SettingsController.get().then(data => {
            setSettings(data.data);
        })
    }, [])

    function removeEntry(id: number) {
        setCarbos(carbos.filter(c => c.id !== id))
        setDeletedCarbosIds([...deletedCarbosIds, id])
    }
    
    function sortCarbos(a: TestCarboJobInput, b: TestCarboJobInput) {
        return a.sampleNumber.localeCompare(b.sampleNumber, undefined, {
            numeric: true,
        })
    }

    return (
        <div>
            <Dialog title={'Job Card Details'} show={true} setShow={cancel}>

                {settings == null
                    ? 'Loading settings...'
                    :
                    <div>
                        {
                            props.completed ? <div onClick={() => exportJob()} className='btn bg-primary m-1 mx-2'>Export Job</div> : null
                        }

                        <table ref={tableRef} className="m-2">
                            <thead>
                            <tr>
                                <td className="pr-2">Sample Number</td>
                                <td className="pr-2">Sugar
                                    {
                                        anySugar() ?
                                            <span
                                                className="text-xs text-red-500 ">  (Max limit is {settings.sugarLimit})</span> : null
                                    }
                                </td>
                                <td className="pr-2">Starch
                                    {
                                        anyStarch() ?
                                            <span
                                                className="text-xs text-red-500 ">  (Max limit is {settings.starchLimit})</span> : null
                                    }
                                </td>
                                <td className="pr-2">N/A</td>
                            </tr>
                            </thead>
                            <tbody>
                            {carbos.sort((a, b) => sortCarbos(a, b)).map((tc, index) => <Fragment key={tc.id}>
                                <tr>
                                    <td className="pr-2">{tc.sampleNumber}</td>
                                    <td className="pr-2 " onKeyDown={e => keyDown(e.key, 0, index)}>
                                        < NumberNullable
                                            className={inputStyling((tc.sugar ?? 0) > context.initial.settings.sugarLimit)}
                                            disabled={tc.na} value={tc.sugar}
                                            change={(state) => setCarbos(arrayUpdatePartial(carbos, index, {sugar: state}))}/>

                                    </td>
                                    <td className="pr-2" onKeyDown={e => keyDown(e.key, 1, index)}>
                                        <NumberNullable
                                            className={inputStyling((tc.starch ?? 0) > context.initial.settings.starchLimit)}
                                            disabled={tc.na} value={tc.starch}
                                            change={(state) => setCarbos(arrayUpdatePartial(carbos, index, {starch: state}))}/>
                                    </td>
                                    <td className="pr-2 text-center">
                                        <CheckBox checked={tc.na}
                                                  onChange={(state) => setCarbos(arrayUpdatePartial(carbos, index, {na: state}))}></CheckBox>
                                    </td>
                                    <td>
                                        <button className="m-1 btn-error btn-sm" 
                                                onClick={ _ => warningState.show('Are you sure you want to redo sample '+ tc.sampleNumber +'? It will be removed from this job.', tc.id)}>
                                            x
                                        </button>
                                    </td>
                                </tr>
                            </Fragment>)}
                            </tbody>
                        </table>
                        <div className="text-right p-2 border-t sticky bottom-0 bg-white">
                            <button className="btn btn-error" onClick={cancel}>Cancel</button>
                            <div className="btn btn-primary" onClick={updateResult}>Submit</div>
                        </div>
                    </div>}
            </Dialog>
            <WarningPopup state={warningState} onYes={() =>  removeEntry(warningState.state.data)} />
        </div>

)
}

export default ViewJob;
