/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */

enum Originate {
    International = "International",
    Usa = "Usa"
}

export default Originate;
