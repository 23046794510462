import DrisElement from "../controllers/DrisElement";
import React, {useContext, useEffect, useRef, useState} from "react";
import DrisGraph from "../controllers/DrisGraph";
import DrisGraphController from "../controllers/DrisGraphController";
import PagedSearchTable, {PagedTableFunctions} from "../components/PagedSearchTable";
import Dialog from "../components/Dialog";
import Input from "../components/Input";
import {mutate} from "../immutableState";
import {SelectString} from "../components/SelectString";
import {showSuccessOrFailed} from "../Snacks";
import AppContext from "../appContext";
import Failed from "../components/Failed";
import Success from "../components/Success";
import {useValidation} from "../validation";
import WarningPopup, {useWarningState} from "../components/WarningPopup";
import {clone} from "../wrapper";
import Switch from "../components/Switch";

const elements: Record<string, string> = {
    Ca: "Ca",
    Cu: "Cu",
    Fe: "Fe",
    K: "K",
    Mg: "Mg",
    Mn: "Mn",
    N: "N",
    Na: "Na",
    P: "P",
    S: "S",
    Zn: "Zn",
    B: 'B'
}

function newDrisGraph(): DrisGraph {
    return {
        name: '',
        formula: {
            relationships: [],
            values: []
        },
        id: 0,
        alternateFunction: false
    }
}


const DrisGraphs: React.FC = (props) => {

    const [data, setData] = useState<DrisGraph[]>([]);
    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState<DrisGraph | null>(null);
    const pagedTableRef = useRef<PagedTableFunctions<DrisGraph>>()


    useEffect(() => {
        DrisGraphController.getAll().then(resp => {
            setData(resp.data);
        })
    }, [])

    function editGraph(graph: DrisGraph) {
        setShow(true);
        setEdit(graph);
    }

    return <div className="bg-white p-2 shadow">
        <div className="btn btn-primary mb-2" onClick={() => editGraph(newDrisGraph())}>+</div>

        <PagedSearchTable componentRef={pagedTableRef} call={DrisGraphController.paged} columns={[
            {
                header: 'Name',
                row: row => row.name
            },
            {
                header: <div className='text-right'>Actions</div>,
                row: row => <div className='text-right'><a onClick={() => editGraph(row)} className="btn-sm btn-primary">edit</a></div>
            }
        ]}/>

        <Dialog title="Graph Builder" show={show} setShow={setShow}>
            {edit != null
                ? <DrisGraphBuilder data={edit} close={saved => {
                    setShow(false)
                    if (saved) {
                        pagedTableRef.current?.focus()
                        pagedTableRef.current?.refresh()
                    }
                }}/>
                : null}
        </Dialog>

    </div>
}

const DrisGraphBuilder: React.FC<{
    data: DrisGraph;
    close: (saved: boolean) => void;
}> = (props) => {

    const context = useContext(AppContext);
    const [data, setData] = useState<DrisGraph>(clone(props.data));
    const [alternateFunction, setAlternateFunction] = useState(false);
    
    const warningStateValues = useWarningState<number>(0)
    const warningStateRelationships = useWarningState<number>(0)

    const validation = useValidation({
        name: () => data.name.length > 0,
        values: () => data.formula.values.length > 0
    });

    function addRelationship() {
        setData(mutate(data, d => d.formula.relationships.push({
            expression: '',
            name: ''
        })));
    }

    function removeRelationship(index: number) {
        setData(mutate(data, d => d.formula.relationships.splice(index, 1)))
    }

    function update() {
        if (!validation.validate()) return;
        
        const prom = data.id == 0
            ? DrisGraphController.insert(data)
            : DrisGraphController.update(data);
        
        const hideLoader = context.showLoader();
        prom.then(res => {
            if (res.data.error) {
                context.showSnack(<Failed title={res.data.message}/>)
            } else {
                context.showSnack(<Success title={"success"}/>)
                props.close(true);
            }
        }).finally(() => {
            hideLoader();
        })
    }

    function addValue() {
        setData(mutate(data, d => d.formula.values.push({
            expression: '',
            element: DrisElement.N
        })));
    }

    function removeValue(index: number) {
        setData(mutate(data, d => d.formula.values.splice(index, 1)))
    }

    return <div className="p-2">
        {
            !validation.rules.name ? <div className='text-red-500 text-sm'>Graph name required</div> : null
        }
        <Input className={!validation.rules.name ? 'ring-2 ring-red-500' : ''} value={data.name} change={v => setData({...data, name: v})}/>
        
        <div className="p-1 font-bold text-gray-800">Relationships</div>
        <div>
            {data.formula.relationships.map((rel, index) =>
                <div key={index} className="flex items-center pb-1">
                    <Input value={rel.name}
                           change={v => setData(mutate(data, data => data.formula.relationships[index]!.name = v))}/>
                    <span className='px-1'>=</span>
                    <Input value={rel.expression}
                           change={v => setData(mutate(data, data => data.formula.relationships[index]!.expression = v))}/>
                    <span onClick={() => warningStateRelationships.show("Are you sure you want to delete this relationship? ", index)} className="m-1 btn-error btn-sm">x</span>
                </div>
            )}
        </div>

        <div className="">
            <button onClick={() => addRelationship()} className="btn-sm bg-primary m-1">Add</button>
        </div>


        <div className='flex items-center mt-2 p-1'>
            <div className="font-bold text-gray-800">Values</div>
            {
                !validation.rules.values ? <div className='text-red-500 pl-1 text-sm'>- required</div> : null
            }
        </div>
        

        {data.formula.values.map((value, index) =>
            <div className="flex items-center mb-1" key={index}>
                <SelectString options={elements} value={value.element}
                              onChange={v => setData(mutate(data, data => data.formula.values[index]!.element = v as DrisElement))}/>
                <span className='px-1'>=</span>
                <Input value={value.expression}
                       change={v => setData(mutate(data, data => data.formula.values[index]!.expression = v))}/>
                <span onClick={() => warningStateValues.show("Are you sure you want to delete this value? ", index)} className="m-1 btn-error btn-sm">x</span>
            </div>
        )}

        <div className="">
            <button onClick={() => addValue()} className="btn-sm bg-primary m-1">Add</button>
        </div>

        {/*Commented out for now until AT approves*/}
        {/*<div className="p-1 font-bold text-gray-800 mt-2">F-function</div>*/}
        {/*<div className='flex items-center justify-start m-1 py-1'>*/}
        {/*    <div>Original</div>*/}
        {/*    <div className='flex items-center px-2'>*/}
        {/*        <Switch checked={data.alternateFunction} setChecked={(v) => setData(mutate(data, data => data.alternateFunction = v))}/>*/}
        {/*    </div>*/}
        {/*    <div>Alternate</div>*/}
        {/*</div>*/}

        <div className="text-right p-2 border-t sticky bottom-0 bg-white">
            <div className="btn btn-error" onClick={() => props.close(false)}>discard</div>
            <div onClick={() => update()} className="btn bg-primary m-1">{data.id === 0 ? 'add' : 'update'}</div>
        </div>
        
        <WarningPopup state={warningStateValues} onYes={() => removeValue(warningStateValues.state.data)}/>
        <WarningPopup state={warningStateRelationships} onYes={() => removeRelationship(warningStateRelationships.state.data)}/>

    </div>

}


export default DrisGraphs;