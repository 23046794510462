/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type SettingsDisplay from "./SettingsDisplay";
import type Void from "./Void";

export default {
    get(): Promise<AxiosResponse<SettingsDisplay>> {
        return Axios.post("/api/settings/get", defaultConfig);
    },
    update(settings: SettingsDisplay): Promise<AxiosResponse<Void>> {
        return Axios.post("/api/settings/update", settings, defaultConfig);
    }
}
