/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type PaginationResponse from "./PaginationResponse";
import type Distributor from "./Distributor";
import type PaginationRequestSearch from "./PaginationRequestSearch";
import type DistributorUpsert from "./DistributorUpsert";

export default {
    paged(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<Distributor>>> {
        return Axios.post("/api/distributor/paged", request, defaultConfig);
    },
    all(): Promise<AxiosResponse<Distributor[]>> {
        return Axios.post("/api/distributor/all", defaultConfig);
    },
    upsert(request: DistributorUpsert): Promise<AxiosResponse> {
        return Axios.post("/api/distributor/upsert", toFormData(request), defaultFormConfig);
    }
}
