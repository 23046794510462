import {classNames} from "../../wrapper";
import React, {Fragment, useContext} from "react";
import AppContext from "../../appContext";
import {Tab} from "@headlessui/react";
import Languages from "./Languages";
import Keys from "./Keys";


function buttonClass(selected: boolean): string {
    return classNames('w-full rounded-lg text-sm font-medium leading-5 text-primary-700 ring-white ring-opacity-60 ring-offset-2 ring-offset-primary-400 focus:outline-none focus:ring-2', selected
        ? 'bg-white shadow'
        : 'text-primary-100 hover:bg-white/[0.12] hover:text-white');
}

const Language: React.FC = () => {
    const context = useContext(AppContext);

    return (
        <div className="bg-white p-2 shadow">
            <Tab.Group defaultIndex={-1}>
                <Tab.List className="flex space-x-1 rounded-xl bg-primary-500 p-1">
                    <Tab as={Fragment}>
                        {({selected}) => (
                            <button className={buttonClass(selected)}>
                                Languages
                            </button>
                        )}
                    </Tab>
                    <Tab as={Fragment}>
                        {({selected}) => (
                            <button className={buttonClass(selected)}>
                                Keys
                            </button>
                        )}
                    </Tab>
                </Tab.List>

            <Tab.Panels>
                <Tab.Panel>
                    <Languages/>
                </Tab.Panel>
                <Tab.Panel>
                    <Keys/>
                </Tab.Panel>
            </Tab.Panels>
            </Tab.Group>
        </div>
    )}

export default Language;
    