/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type Role from "./Role";
import type PaginationResponse from "./PaginationResponse";
import type RoleDisplay from "./RoleDisplay";
import type PaginationRequestSearch from "./PaginationRequestSearch";
import type RoleRequest from "./RoleRequest";
import type IdRequest from "./IdRequest";

const urls = {
    index(): string {
        return "/api/role/index";
    }
}

export default {
    urls,
    index(): Promise<AxiosResponse<Role[]>> {
        return Axios.get("/api/role/index", defaultConfig);
    },
    paged(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<RoleDisplay>>> {
        return Axios.post("/api/role/paged", request, defaultConfig);
    },
    upsert(request: RoleRequest): Promise<AxiosResponse> {
        return Axios.post("/api/role/upsert", request, defaultConfig);
    },
    delete(request: IdRequest): Promise<AxiosResponse> {
        return Axios.post("/api/role/delete", request, defaultConfig);
    }
}
