import React, {PropsWithChildren, useEffect, useRef} from 'react'
import ReactDOM from 'react-dom'

const dialogRoot = document.getElementById('dialogs')!

const DialogPortal: React.FC<PropsWithChildren<{
    mounted?: (el: HTMLDivElement) => void
}>> = (props: PropsWithChildren<{
    mounted?: (el: HTMLDivElement) => void
}>) => {
    const el = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (el.current) { props.mounted?.(el.current) }
    }, [])

    return ReactDOM.createPortal(
        <div ref={el}>{props.children}</div>,
        dialogRoot
    )
}
export default DialogPortal
