/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type PaginationResponse from "./PaginationResponse";
import type FarmManagementResponse from "./FarmManagementResponse";
import type PaginationRequestSearch from "./PaginationRequestSearch";

export default {
    paged(request: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<FarmManagementResponse>>> {
        return Axios.post("/api/farm/paged", request, defaultConfig);
    }
}
