import React, {useImperativeHandle} from "react";
import {classNames} from "../../wrapper";
import Number from "../../components/Number";
import {arrayUpdatePartial} from "../../immutableState";
import IdName from "../../controllers/IdName";
import CheckBox from "../../components/CheckBox";
import GenericGrowthValue from "../../controllers/GenericGrowthValue";
import TestCarboCropGrowth from "../../controllers/TestCarboCropGrowth";
import CarboCropGrowthDisplay from "../../controllers/CarboCropGrowthDisplay";
function populateGrowths(props: { growths: GenericGrowthValue[], availableGrowthStages: CarboCropGrowthDisplay[] }): GenericGrowthValue[] {
    return props.availableGrowthStages.map(g => {
        const growth = props.growths.find(gg => gg.growthStageId === g.id);
        if (growth) return growth;
        return {
            growthStageId: g.id,
            override: false,
            sugar: {min: 0, max: 0, med: 0},
            starch: {min: 0, max: 0, med: 0},
            linkedTissueId: g.linkedTissueId,
            linkedTissueName: g.linkedTissueName
        };
    });
}

export interface GrowthsTableProps {
    getData: () => GenericGrowthValue[];
}

const GrowthsTable: React.ForwardRefRenderFunction<GrowthsTableProps, {
    growths: GenericGrowthValue[]
    availableGrowthStages: CarboCropGrowthDisplay[]
}> = (props,ref) => {
    const [growths, setGrowths] = React.useState<GenericGrowthValue[]>(populateGrowths(props));

    useImperativeHandle(ref, () => ({
        getData: () => growths
    }), [growths]);

    function findGrowthName(id: number) {
        const growth = props.availableGrowthStages.find(g => g.id === id);
        if (growth) return growth.name;
        return "";
    }
    
    return <div>
        <table className=" divide-y divide-gray-300  m-2">
            <thead className="bg-gray-50">
            <tr>
                <td className="uppercase pr-5 py-2 text-gray-500 text-left text-sm font-semibold">Override</td>
                <td className="uppercase pr-5 py-2 text-gray-500 text-left text-sm font-semibold min-w-[200px]">Name</td>
                <td className="uppercase pr-5 py-2 text-gray-500 text-left text-sm font-semibold">Organ Types</td>
                <td className="uppercase py-2 text-gray-500 text-left text-sm font-semibold">Sugar</td>
                <td className="uppercase py-2 text-gray-500 text-left text-sm font-semibold">Starch</td>
            </tr>
            <tr className=''>
                <td></td>
                <td></td>
                <td></td>
                <td className="uppercase pr-2 pb-2 text-gray-500 text-left text-sm font-semibold">
                    <div className='flex min-w-[200px]'>
                        <div className='w-1/2'>min</div>
                        <div className='w-1/2'>max</div>
                    </div>
                </td>
                <td className="uppercase pr-2 pb-2 text-gray-500 text-left text-sm font-semibold">
                    <div className='flex min-w-[200px]'>
                        <div className='w-1/2'>min</div>
                        <div className='w-1/2'>max</div>
                    </div>
                </td>
                <td></td>
            </tr>
            </thead>
            <tbody className="bg-white">
            {
                growths.map((row, index) => <tr key={row.growthStageId}
                                                className={classNames(index % 2 === 0 ? '' : 'bg-gray-50', 'group hover:bg-gray-200 cursor-pointer')}>
                    <CheckBox checked={row.override} onChange={v => {
                        const newGrowths = arrayUpdatePartial(growths, index, {override: v});
                        setGrowths(newGrowths);
                    }}/>
                    <td className='pr-3 py-1 whitespace-nowrap'>{findGrowthName(row.growthStageId)}</td>
                    <td className='pr-3 py-1 whitespace-nowrap'>{row.linkedTissueName}</td>
                    <td className='pr-3 py-1'>
                        <div className='flex'>
                            <Number value={row.sugar.min} change={v => {
                                const newGrowths = arrayUpdatePartial(growths, index, {sugar: {...row.sugar, min: v}});
                                setGrowths(newGrowths);
                            }}/>
                            <Number value={row.sugar.max} change={v => {
                                const newGrowths = arrayUpdatePartial(growths, index, {sugar: {...row.sugar, max: v}});
                                setGrowths(newGrowths);
                            }}/>
                        </div>
                    </td>
                    <td className='pr-3 py-1'>
                        <div className='flex'>
                            <Number value={row.starch.min} change={v => {
                                const newGrowths = arrayUpdatePartial(growths, index, {
                                    starch: {
                                        ...row.starch,
                                        min: v
                                    }
                                });
                                setGrowths(newGrowths);
                            }}/>
                            <Number value={row.starch.max} change={v => {
                                const newGrowths = arrayUpdatePartial(growths, index, {
                                    starch: {
                                        ...row.starch,
                                        max: v
                                    }
                                });
                                setGrowths(newGrowths);
                            }}/>
                        </div>
                    </td>
                </tr>)
            }
            </tbody>
        </table>
    </div>
}

export default React.forwardRef(GrowthsTable);
