import React, {useContext, useRef, useState} from "react";
import AppContext from "../../appContext";
import CarbohydrateTissueRequest from "../../controllers/CarbohydrateTissueRequest";
import PagedSearchTable, {PagedTableFunctions} from "../../components/PagedSearchTable";
import {showSuccessOrFailed} from "../../Snacks";
import Dialog from "../../components/Dialog";
import {EditRow, EditTable} from "../../components/Fields";
import Input from "../../components/Input";
import {buildSetter} from "../../immutableState";
import {useValidation} from "../../validation";
import CarbohydrateTissueController from "../../controllers/CarbohydrateTissueController";
import WarningPopup, {useWarningState} from "../../components/WarningPopup";
import CheckBox from "../../components/CheckBox";

function emptyCarbohydrateTissueRequest(): CarbohydrateTissueRequest {
    return {
        id: 0,
        name: '',
        active: false
    }
}

const Tissues: React.FC = () => {
    const context = useContext(AppContext);
    const pagedTableRef = useRef<PagedTableFunctions<CarbohydrateTissueRequest>>()


    const [selected, setSelected] = useState<CarbohydrateTissueRequest>(emptyCarbohydrateTissueRequest)
    const [editModel, setEditModel] = useState(false)
    const [setData] = buildSetter(selected, setSelected)
    const warningState = useWarningState<number>(0)

    const validation = useValidation({
        name: () => selected.name.length > 0,
    })

    function upsertModel() {
        if (!validation.validate()) return 
        
        showSuccessOrFailed(context, CarbohydrateTissueController.upsert(selected)).then(resp => {
            setEditModel(false)
            pagedTableRef.current?.refresh();
        })
    }

    function newEntry() {
        setSelected({name: '', id: 0, active: false})
        setEditModel(true)
    }

    function edit(row: CarbohydrateTissueRequest) {
        setSelected({...row})
        setEditModel(true)
    }
    
    function deleteTissue(id: number){
        showSuccessOrFailed(context, CarbohydrateTissueController.delete({id: id})).then(() => {
            pagedTableRef.current?.refresh();
        })
    }
    
    function updateActive(id: number, v: boolean) {
        showSuccessOrFailed(context, CarbohydrateTissueController.tissueActive({id: id, active: v})).then(() => {
            pagedTableRef.current?.refresh();
        })
    }
    
    return (
        <div className="bg-white p-2 shadow">
            <div className="pb-2">
                <div className="btn bg-primary" onClick={newEntry}>Add</div>
            </div>
            <PagedSearchTable componentRef={pagedTableRef} call={CarbohydrateTissueController.paged} columns={[
                {
                    header: 'Name',
                    key: 'name',
                    row: (row, index) => row.name
                },
                {
                    header: 'Active',
                    key: 'active',
                    row: (row) => <CheckBox checked={row.active} onChange={v => updateActive(row.id, v)}/>
                },
                {
                    header: <div className='text-right'>Actions</div>,
                    key: 'actions',
                    row: (row, index) => <div className='text-right'>
                        <div className="bg-primary btn-sm" onClick={() => edit(row)}>edit</div>
                        <div className="bg-red-500 btn-sm" onClick={() => warningState.show(row.name === '' ? 'Are you sure you want to delete this row?' : `Are you sure you want to delete ${row.name}?`, row.id)}>x</div>
                    </div>
                },
            ]}/>
            <WarningPopup state={warningState} onYes={() => deleteTissue(warningState.state.data)}/>
            <Dialog title={'Tissue'}
                    show={editModel}
                    setShow={setEditModel}
                    body={<>
                        <EditTable discard={() => setEditModel(false)} save={() => upsertModel()}
                                   saveWord={selected.id === 0 ? 'add' : 'update'}>
                            {EditRow('Name', <Input value={selected.name}
                                                    change={v => setData({name: v})}/>, validation.rules.name, 'Name required')}
                        </EditTable>
                    </>}
            />
        </div>
    )
}
export default Tissues