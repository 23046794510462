/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */

enum UserLoginCode {
    Success = "Success",
    DoesNotExists = "DoesNotExists",
    Archived = "Archived",
    InActive = "InActive",
    Locked = "Locked",
    WrongPassword = "WrongPassword"
}

export default UserLoginCode;
