/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */

enum TestType {
    Unknown = "Unknown",
    MineralPackageWaterSoluble = "MineralPackageWaterSoluble",
    ItestPecans = "ItestPecans",
    LeafSap = "LeafSap",
    WetAndDry = "WetAndDry",
    StandardItestLeaf = "StandardItestLeaf",
    ItestLeafInclPh = "ItestLeafInclPh",
    ItestLeafInclBrix = "ItestLeafInclBrix",
    ItestLeafInclBrixPh = "ItestLeafInclBrixPh",
    ItestLeafNuPotiolo = "ItestLeafNuPotiolo",
    Chloride = "Chloride",
    StandardItestPotiolo = "StandardItestPotiolo",
    ItestRoot = "ItestRoot",
    Nickel = "Nickel",
    SilicaWaterSoluble = "SilicaWaterSoluble"
}

export default TestType;
