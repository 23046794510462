import React, { useContext } from 'react'
import AppContext from '../appContext'
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import {classNames} from "../wrapper";

const Pagination: React.FC<{
    total: number,
    rowsPerPage: number,
    page: number,
    gotoPage: (page: number) => void,
    setRowsPerPage: (rows: number) => void,
    refresh: () => void;
}> = (props) => {
    const totalPages = Math.ceil(props.total / props.rowsPerPage)
    const start = (props.page * props.rowsPerPage) + 1
    const end = start + props.rowsPerPage - 1
    const app = useContext(AppContext)
    const pageRows = [5, 10, 20, 30, 50, 100, 200, 500, 1000]

    function previous () {
        if (props.page > 0) { props.gotoPage(props.page - 1) }
    }

    function next () {
        if (props.page < totalPages - 1) { props.gotoPage(props.page + 1) }
    }
    
    function rowsPerPageStyling(rowsPerPage: number) {
        return classNames('m-1 p-1 rounded w-10 text-center cursor-pointer', props.rowsPerPage === rowsPerPage ? 'bg-primary text-white' : '')
    }
    
    function pageNumberStyling(pageNumber: number) {
        return classNames('m-1 p-1 rounded w-10 text-center cursor-pointer', props.page + 1 === pageNumber  ? 'bg-primary text-white' : '')
    }
    
    function pageOptions(){
        const start = Math.max(1, props.page -3)
        const end = Math.min(maxPage(), start + 6)
        if (end - start > 0) {
            return new Array(end - start).fill(0).map((_, i) => i + start)
        }
    }
    
    function maxPage() {
        return Math.ceil(props.total / props.rowsPerPage) - 1 
    }

    return (
        <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
            aria-label="Pagination"
        >

            <div className="flex-1 flex justify-between items-center sm:justify-end">
                <div className="flex items-center text-gray-500 px-4">
                    <span className='text-gray-900'>Rows per page: </span>
                    {
                        pageRows.map(row => <div key={row} className={rowsPerPageStyling(row)} onClick={() => props.setRowsPerPage(row)}>{row}</div>)
                    }
                    <div className='m-1 mx-2'> | </div>
                    <span className='text-gray-900'>Page: </span>
                    <div className={pageNumberStyling(1)} onClick={() => props.gotoPage(0)}>First</div>
                    {
                        pageOptions()?.map(page => <div key={page} className={pageNumberStyling(page+1)} onClick={() => props.gotoPage(page)}>{page + 1}</div>)
                    }
                    {maxPage() !== 0
                        ? <div className={pageNumberStyling(maxPage() + 1)}
                          onClick={() => props.gotoPage(maxPage())}>Last</div>
                        : null
                    }
                </div>
            </div>

            <div className="hidden sm:block">
                <p className="text-gray-900">
                    Showing <span className="font-medium">{start}</span> to <span
                    className="font-medium">{Math.min(end, props.total)}</span> of{' '}
                    <span className="font-medium">{props.total}</span>
                </p>
            
            </div>
            <div className='h-5 w-5 mx-2'>
                <ArrowPathIcon className='cursor-pointer' onClick={() => props.refresh()} />
            </div>
        </nav>
    )
}

export default Pagination
