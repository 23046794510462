import React, {useContext, useEffect, useRef, useState} from "react";
import Input from "../components/Input";
import {Link, useNavigate, useRoutes, useSearchParams} from "react-router-dom";
import AppContext from "../appContext";
import AuthController from "../controllers/AuthController";
import {bind, wrapLoader} from "../wrapper";
import {useValidation} from "../validation";

const Token: React.FC = (props) => {
    const context = useContext(AppContext)
    const [query] = useSearchParams()
    const token = useRef<string>(query.get('token') ?? '')
    const [tokenValid, setTokenValid] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        AuthController.checkToken({token: token.current}).then(res => {
            setTokenValid(res.data)
            setLoading(false)
        })
    }, [])

    function submit() {
        if (!validation.validate())
            return
        
        wrapLoader(context, AuthController.reset({
            password: password,
            token: token.current
        }), success => {
            if (success) {
                navigate('login')                
                return;
            }
            
            console.log('Finsihed resetting password')
        })
           
    }
    
    function passError(){
        return <div className= "text-red-400 text-xs  justify-left" >Password is required</div>
    }

    function confirmError(){
        return <div className= "text-red-400  justify-left text-xs" > Password and Confirm Password should match</div>
    }
    
    const validation = useValidation({
        passwordRequired: () => password.length > 0,
        confirmPasswordRequired: () => confirmPassword == password
    })
    
    if (loading) {
        return <div>Checking token...</div>
    }


    return (
        <>
        
            <div className=" right-0 p-1 text-xs">
                <div
                    style={{
                        backgroundImage: 'url(/images/banner.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'top left',
                        backgroundSize: 'cover'
                    }}
                    className="min-h-screen flex items-center right-0 bg-gray-50 py-12 px-4 sm:px-6 lg:px-32 flex-row-reverse">
                    <div className="mx-auto md:mx-8 p-8 bg-white border-t-4 border-primary rounded-lg text-center shadow-xl flex items-center flex-col md:flex-row">
                        <div className="md:border-r md:pr-4 text-right">
                            <img className="mx-auto h-32 w-auto mt-2" src="/images/logo.png" alt="logo"/>
                            <Link to="/login" className="font-medium text-primary-600 hover:text-primary-500  mx-3 ">
                                Back to Login
                            </Link>
                        </div>
                       
            
                        {tokenValid
                            ? <div >
                                <div >
                                    <div className="mb-1 mx-4 group relative w-full">
                                        <div className="text-xs text-gray-800 p-2 text-left label-color">New Password {!validation.rules.passwordRequired ? passError() : null }</div>
                                        <label htmlFor="password" className="sr-only ">Password</label>
                                        <input type="password" className= "input" {...bind(password, setPassword)}/>
                                        
                                        
                                    </div>
                                    <div className="mb-4 mx-4 group relative w-full">
                                        <div className="text-xs text-gray-800 p-2 text-left label-color">Confirm Password {!validation.rules.confirmPasswordRequired ? confirmError() : null }</div>
                                        <label htmlFor="confirm-password" className="sr-only w-full flex"> Confirm Password </label>
                                        <input type="password" className= "input" {...bind(confirmPassword,setConfirmPassword)}/>
                                    </div>
                                </div>
                
                                <button type="submit" onClick={() => submit()}
                                        className="group relative w-full flex justify-center my-4 mx-4 py-2 mt-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                            <svg className="h-5 w-5 text-primary-500 group-hover:text-primary-400"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd"
                                                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                                      clip-rule="evenodd"/>
                                            </svg>
                                        </span>
                                    Reset
                                </button>
                                
                            </div>
                            : <div className="mb-4 mx-4 group relative ">Your token has expired. Please reset your password again.</div>}
                    </div>    
                </div>
                
            </div>
        </>
    )    
}

export default Token;