import MacroGraph from "../../controllers/MacroGraph";
import MicroGraph from "../../controllers/MicroGraph";
import ElementValue from "../../controllers/ElementValue";
import Molybdenum from "../../controllers/Molybdenum";
import Nickel from "../../controllers/Nickel";
import Nitrate from "../../controllers/Nitrate";
import Ammonium from "../../controllers/Ammonium";

function elementValue(): ElementValue {
    return {
        max: 0,
        med: 0,
        min: 0
    }
}

export function emptyMicro(): MicroGraph {
    return {
        id: 0,
        fe: elementValue(),
        mn: elementValue(),
        na: elementValue(),
        zn: elementValue(),
        cu: elementValue(),
        b: elementValue()
    }
}

export function emptyMacro(): MacroGraph {
    return {
        id: 0,
        n: elementValue(),
        p: elementValue(),
        k: elementValue(),
        ca: elementValue(),
        mg: elementValue(),
        s: elementValue()
    }
}

export function emptyMolybdenum(): Molybdenum {
    return {
        id: 0,
        mo: elementValue()
    }
}

export function emptyNickel(): Nickel {
    return {
        id: 0,
        ni: elementValue()
    }
}

export function emptyNitrate(): Nitrate {
    return {
        id: 0,
        no3: elementValue()
    }
}

export function emptyAmmonium(): Ammonium {
    return {
        id: 0,
        nh4: elementValue()
    }
}