import React, {Dispatch, PropsWithChildren, SetStateAction, useCallback, useEffect, useRef, useState} from 'react'
import DialogPortal from './DialogPortal'
import {
    XMarkIcon
} from '@heroicons/react/24/outline'
import { classNames } from '../wrapper'

interface DialogProps {
    title: React.ReactNode;
    body?: React.ReactNode;
    show: boolean;
    modal?: boolean;
    setShow?: Dispatch<SetStateAction<boolean>>
    // forward from DialogPortal
    mounted?: (el: HTMLDivElement) => void;
}

interface DialogInstance {
    id: number;
    visible: boolean;
    outsideClickClose: boolean;
}

let id: number = 0
let dialogs: DialogInstance[] = []


const Dialog: React.FC<PropsWithChildren<DialogProps>> = (props) => {
   
    const overlayRef = useRef<HTMLDivElement>(null)
    const dialogDraggable = useRef<HTMLDivElement>(null)
    
    const [overlay, setOverlay] = useState(props.show)
    const [mouseDown, setMouseDown] = useState(false)
    const [diff, setDiff] = useState({
        x: 0,
        y: 0
    })
    const [position, setPosition] = useState({
        x: 0,
        y: 0
    })

    const instance = useRef<DialogInstance>({
        visible: props.show,
        id: id++,
        outsideClickClose: props.modal ?? false
    })

    function moveTo (dialog: HTMLElement, position: { x: number; y: number }) {
        setPosition({
            x: position.x,
            y: position.y
        })
        dialog.style.left = position.x + 'px'
        dialog.style.top = position.y + 'px'
        dialog.style.position = 'fixed'
    }
    function hide () {
        if (props.setShow) {
            // will set overlay to false in useEffect
            props.setShow(false)
        }
    }

    useEffect(() => {
        instance.current.visible = props.show
        setOverlay(props.show)
    }, [props.show])

    function keydown (e: KeyboardEvent) {
        if (e.key === 'Escape' && dialogs.length > 0) {
            const visible = dialogs.reverse().find(d => d.visible)
            // we are top dialog. Only close this one
            if (visible === instance.current) {
                hide()
            }
        }
    }

    function click (e: MouseEvent) {
        if (dialogs.length == 0)
            return;
        
        if (overlayRef.current === e.target && dialogs[dialogs.length - 1]!.outsideClickClose) {
            hide()
        }
    }


    useEffect(() => {
        dialogs.push(instance.current)
        window.addEventListener('click', click)
        window.addEventListener('keydown', keydown)

        return () => {
            window.removeEventListener('click', click)
            window.removeEventListener('keydown', keydown)

            // remove dialog instance.
            dialogs = dialogs.filter(d => d != instance.current)
        }
    }, [])

    return (
        props.show
            ? <DialogPortal mounted={props.mounted}>
                <div ref={overlayRef}
                     onMouseMove={e =>{
                         if (e.buttons !== 1 || !mouseDown) return

                         const dialog = dialogDraggable.current
                         if (!dialog) return

                         moveTo(dialog, {
                             x: e.clientX - diff.x,
                             y: e.clientY - diff.y
                         })
                         setOverlay(false)
                     }}
                     className={classNames('absolute inset-0 flex justify-center items-center z-10', overlay ? 'overlay' : 'overlayClear')}>
                    
                    <div
                        ref={dialogDraggable}
                        className="max-w-full animate-modal z-50">
                        <div
                            onMouseDown={e => {
                                const dialog = dialogDraggable.current
                                if (!dialog) return
                                let rect = dialog.getBoundingClientRect()
                                const x = e.clientX - rect.left
                                const y = e.clientY - rect.top

                                setMouseDown(true)
                                setDiff({x, y})
                            }}

                            onMouseUp={() => {
                                setMouseDown(false)
                            }}
                            className={classNames("flex w-full items-center bg-primary-500 rounded-t-md text-white p-2 cursor-pointer", overlay ? '' : 'border-x-2 border-t-2 border-gray-400 border-opacity-50')}>
                            <div>{props.title}</div>
                            {props.setShow ?
                                <div className="ml-auto cursor-pointer" onClick={(e) => {
                                hide()
                            }}>
                                <XMarkIcon className="ml-2 h-4"/>
                            </div> : null}
                        </div>

                        <div className={classNames("bg-white h-full overflow-auto", overlay ? '' : 'border-x-2 border-b-2 border-gray-400 border-opacity-50')} style={{ maxHeight: '70vh' }}>
                            {props.body != undefined ? props.body : props.children}
                        </div>
                    </div>
                </div>
            </DialogPortal>
            : null
    )
}

export default Dialog
