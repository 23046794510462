import GraphType from "../../controllers/GraphType";
import {
    arrayPush,
    arrayRemoveIndex,
    arrayUpdate,
    arrayUpdatePartial,
    ArrayUpdateType,
    useStateSetter,
    useStateSetterArray
} from "../../immutableState";
import AppContext from "../../appContext";
import React, {useContext, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {groupBy, groupBySingle} from "../../array";
import SelectNumberNullable from "../../components/SelectNumberNullable";
import {DatePicker, DatePickerType} from "../../components/DatePicker";
import {Fields} from "../../components/Fields";
import SelectNumber from "../../components/SelectNumber";
import CheckBox from "../../components/CheckBox";
import Dialog from "../../components/Dialog";
import UpdateReportDataRequest from "../../controllers/UpdateReportDataRequest";
import {classNames, wrapLoader} from "../../wrapper";
import Input from "../../components/Input";
import TestLeafReportDataResponse from "../../controllers/TestLeafReportDataResponse";
import {SelectString} from "../../components/SelectString";
import UpdateReportDataLeafRequest from "../../controllers/UpdateReportDataLeafRequest";
import ReportDataResult from "../../controllers/ReportDataResult";
import SubmissionReportDataResponse from "../../controllers/SubmissionReportDataResponse";
import Success from "../../components/Success";
import Failed from "../../components/Failed";
import {XCircleIcon} from "@heroicons/react/24/outline";
import PreReportData from "../../controllers/PreReportData";
import SubmissionController from "../../controllers/SubmissionController";
import {useValidation} from "../../validation";
import {checkIfValidForWindowsZip} from "./SubmissionPrepare";

interface ReportHeader {
    submissionId: number;
    languageId: number;
    clientName: string;
    farmName: string;
    agronomistId: number;
    agent: string;
    distributorId: number;
    addressId: number;
    includeSummary: boolean;
    includeIndex: boolean;
    includeGuidelines: boolean;
    includeDrisGraphs: boolean;
    includeCultivarLabels: boolean;
    excludeNa: boolean;
    includeSanas: boolean;
    graphType: GraphType
}

interface ReportLeaf extends UpdateReportDataLeafRequest {
    selected: boolean;
}

interface ReportDataResultEx extends ReportDataResult {
    // update image url
    imageUrl: string;
}

function buildImageUrl(data: ReportDataResult, recommendation: string, whatDoesPlantSay: string): string {
    return SubmissionController.urls.questImage(data.submissionId, data.leafId, recommendation, whatDoesPlantSay);
}

const graphTypes: Record<string, string> = {
    "Old": GraphType.GraphOld,
    "New": GraphType.GraphNew
};

interface ReportRef {
    requestUpdate: () => UpdateReportDataRequest;
}

const ReportForward: React.ForwardRefRenderFunction<ReportRef, {
    leafs: ReportLeaf[];
    data: PreReportData;
    header: ReportHeader;
    id: number;
    extraLabSampleNumbers: string[];
}> = (props, ref) => {
    const [header, setHeader, updateHeader] = useStateSetter(props.header);
    const [leafs, setLeafs, updateLeafs] = useStateSetterArray(props.leafs);

    const [headerCrop, setHeaderCrop] = useState<{ cropId: number, cultivars: number[] }>({cropId: 0, cultivars: []})

    function setSelectedLeafs(data: Partial<ReportLeaf>) {
        setLeafs(leafs.map(leaf => leaf.selected ? {...leaf, ...data} : leaf));
    }


    function changeHeaderCrop(cropId: number) {
        setHeaderCrop({cropId, cultivars: []})
        setSelectedLeafs({
            cropId,
            cultivars: []
        })
    }

    function setSelectedCultivars(cultivars: number[]) {
        setSelectedLeafs({cultivars})
    }

    function addHeaderCultivar() {
        setHeaderCrop({cropId: headerCrop.cropId, cultivars: [...headerCrop.cultivars, 0]})
        setSelectedCultivars([...headerCrop.cultivars, 0])
    }

    function removeHeaderCultivar(cultivarIndex: number) {
        setHeaderCrop({...headerCrop, cultivars: arrayRemoveIndex(headerCrop.cultivars, cultivarIndex)})
        setSelectedCultivars(arrayRemoveIndex(headerCrop.cultivars, cultivarIndex))
    }

    function updateCultivar(index: number, id: number) {
        const updatedCrop = arrayUpdate(headerCrop.cultivars, index, id)
        setHeaderCrop({...headerCrop, cultivars: updatedCrop})
        setSelectedCultivars(updatedCrop)

    }


    const cultivars = useMemo(() => groupBySingle(props.data.crops, c => c.id, c => c.cultivars), [props.data.crops]);
    const growthStages = useMemo(() => groupBy(props.data.growthStages, g => g.cropId), [])

    function addCultivar(index: number, leaf: ReportLeaf) {
        const list = cultivars[leaf.cropId] ?? [];
        if (list.length > 0)
            updateLeafs(index, {cultivars: arrayPush(leaf.cultivars, list[0]!.id)})
    }
    
    function selectedLeafs(): ReportLeaf[] {
        // only download selected leafs if some are selected
        return leafs.filter(l => l.selected)
    }

    const validation = useValidation({
        clientName: () => checkIfValidForWindowsZip(header.clientName),
        farmName: () => checkIfValidForWindowsZip(header.farmName),
    })

    useImperativeHandle(ref, () => ({
        requestUpdate() {
            // run the validation to show if the input is not valid but don't do anything here if it's not valid
            validation.validate()
            return {
                addressId: header.addressId,
                agent: header.agent,
                agronomistId: header.agronomistId,
                clientName: header.clientName,
                distributorId: header.distributorId,
                farmName: header.farmName,
                graphType: header.graphType,
                includeGuidelines: header.includeGuidelines,
                includeCultivarLabels: header.includeCultivarLabels,
                includeDrisGraphs: header.includeDrisGraphs,
                includeIndex: header.includeIndex,
                includeSummary: header.includeSummary,
                excludeNa: header.excludeNa,
                includeSanas: header.includeSanas,
                languageId: header.languageId,
                leafs: selectedLeafs().map<UpdateReportDataLeafRequest>(leaf => ({
                    cropId: leaf.cropId,
                    cultivars: leaf.cultivars,
                    dateCaptured: leaf.dateCaptured,
                    excludeImage: leaf.excludeImage,
                    growthStage: leaf.growthStage,
                    growthStageId: leaf.growthStageId,
                    matchedResult: leaf.matchedResult,
                    id: leaf.id,
                    notes: leaf.notes,
                    sample: leaf.sample,
                    dataId: leaf.dataId,
                    blockNumber: leaf.blockNumber
                })),
                dataId: props.id,
                submissionId: props.header.submissionId
            }
        }
    }));

    return <div>
        <Fields columns={1} fields={[
            {
                label: 'Language',
                value: <SelectNumber options={props.data.languages}
                                     textFunc={r => r.name ?? ''} valueFunc={r => r.id}
                                     value={header.languageId}
                                     onChange={v => updateHeader({languageId: v})}/>
            },
            {
                label: 'Client Name',
                value: <Input className={validation.rules.clientName ? "" : "border border-red-500"} value={header.clientName} change={v => updateHeader({clientName: v})}/>
            },
            {
                label: 'Farm Name',
                value: <Input className={validation.rules.farmName ? "" : "border border-red-500"} value={header.farmName} change={v => updateHeader({farmName: v})}/>
            },
            {
                label: 'Agronomist',
                value: <SelectNumber options={props.data.agronomists} textFunc={r => r.name ?? ''}
                                     valueFunc={r => r.id} value={header.agronomistId}
                                     onChange={v => updateHeader({agronomistId: v})}/>
            },
            {
                label: 'Agent',
                value: <Input value={header.agent} change={v => updateHeader({agent: v})}/>
            },
            {
                label: 'Distributor',
                value: <SelectNumber options={props.data.distributors} textFunc={r => r.name ?? ''}
                                     valueFunc={r => r.id} value={header.distributorId}
                                     onChange={v => updateHeader({distributorId: v})}/>
            },
            {
                label: 'Address',
                value: <SelectNumber options={props.data.addresses} textFunc={r => r.name ?? ''}
                                     valueFunc={r => r.id} value={header.addressId}
                                     onChange={v => updateHeader({addressId: v})}/>
            },
            {
                label: 'Include Summary',
                value: <CheckBox checked={header.includeSummary} onChange={v => updateHeader({includeSummary: v})}/>
            },
            header.includeSummary ?
                {
                    label: <div className="flex items-center">
                        <div className="mx-3 h-2 w-2 bg-gray-700 rounded-full"></div>
                        Include Index
                    </div>,
                    value: <CheckBox className="" checked={header.includeIndex}
                                     onChange={v => updateHeader({includeIndex: v})}/>
                } : null,
            header.includeSummary ?
                {
                    label: <div className="flex items-center">
                        <div className="mx-3 h-2 w-2 bg-gray-700 rounded-full"></div>
                        Include Guidelines
                    </div>,
                    value: <CheckBox className="" checked={header.includeGuidelines}
                                     onChange={v => updateHeader({includeGuidelines: v})}/>
                } : null,

            header.includeSummary ?
                {
                    label: <div className="flex items-center">
                        <div className="mx-3 h-2 w-2 bg-gray-700 rounded-full"></div>
                        Include Drisgraphs
                    </div>,
                    value: <CheckBox className="" checked={header.includeDrisGraphs}
                                     onChange={v => updateHeader({includeDrisGraphs: v})}/>
                } : null,
            header.includeSummary ?
                {
                    label: <div className="flex items-center">
                        <div className="mx-3 h-2 w-2 bg-gray-700 rounded-full"></div>
                        Include Cultivar Labels
                    </div>,
                    value: <CheckBox className="" checked={header.includeCultivarLabels}
                                     onChange={v => updateHeader({includeCultivarLabels: v})}/>
                } : null,
            {
                label: <p>Include SANAS Documents</p>,
                value: <CheckBox checked={header.includeSanas} onChange={v => updateHeader({includeSanas: v})}/>
            },
            {
                label: <p>Exclude Na <span className="text-xs text-gray-500">(Sodium)</span></p>,
                value: <CheckBox checked={header.excludeNa} onChange={v => updateHeader({excludeNa: v})}/>
            },
            {
                label: 'Graph type',
                value: <SelectString options={graphTypes} value={header.graphType}
                                     onChange={v => updateHeader({graphType: v as GraphType})}/>
            }
        ]}/>

        <table className="w-full">
            <thead>
            <tr className='bg-gray-100'>
                <th className="p-2 text-xs">
                    <div>Select</div>
                    <div className="text-center">
                        <CheckBox checked={leafs.every(l => l.selected)}
                                  onChange={v => setLeafs(leafs.map(l => ({...l, selected: v})))}/>
                    </div>
                </th>
                <th className="pl-4 text-xs">Sample</th>
                <th className="pl-4 text-xs">Date</th>
                <th className="pl-4 text-xs">Block</th>
                <th className="pl-4 text-xs">
                    <div>Crop</div>
                    <SelectNumber options={props.data.crops} textFunc={c => c.name ?? ''} valueFunc={c => c.id}
                                  value={headerCrop.cropId} onChange={v => changeHeaderCrop(v)}/>
                </th>
                <th className="pl-4 text-xs">Crop Stage</th>
                <th className="pl-4 text-xs">
                    <div>Cultivar</div>
                    <div className="flex">

                        <div className="btn bg-primary my-1" onClick={() => addHeaderCultivar()}>+</div>
                        {headerCrop.cultivars.map((cultivar, cultivarIndex) =>
                            <div key={cultivarIndex} className="flex">
                                <SelectNumber options={cultivars[headerCrop.cropId] ?? []} textFunc={c => c.name ?? ''}
                                              valueFunc={c => c.id} value={cultivar}
                                              onChange={v => updateCultivar(cultivarIndex, v)}/>
                                <div className="m-1 text-red-500 cursor-pointer"
                                     onClick={() => removeHeaderCultivar(cultivarIndex)}>x
                                </div>

                            </div>
                        )}
                    </div>
                </th>
                <th className="pl-4 text-xs">Graph Values</th>
                <th></th>
                <th className="pl-4 text-xs">Exclude Capture Image</th>
            </tr>

            </thead>
            <tbody>
            {leafs.map((leaf, index) =>
                <tr className={classNames(index % 2 === 0 ? '' : 'bg-gray-100', 'group hover:bg-gray-200 cursor-pointer')}
                    key={leaf.id}>
                    <td>
                        <CheckBox checked={leaf.selected} onChange={v => updateLeafs(index, {selected: v})}/>
                    </td>
                    <td>
                        {leaf.sample}
                        <div className="text-xs text-gray-500">{leaf.notes}</div>
                    </td>
                    <td>
                        <DatePicker value={leaf.dateCaptured} setValue={d => updateLeafs(index, {dateCaptured: d})}
                                    type={DatePickerType.Date}/>
                    </td>
                    <td>
                        <Input value={leaf.blockNumber} change={v => updateLeafs(index, {blockNumber: v})}/>
                    </td>
                    <td>
                        <SelectNumber options={props.data.crops} textFunc={c => c.name ?? ''} valueFunc={c => c.id}
                                      value={leaf.cropId}
                                      onChange={v => updateLeafs(index, {cropId: v, cultivars: []})}/>
                    </td>
                    <td>
                        <Input value={leaf.growthStage} change={v => updateLeafs(index, {growthStage: v})}/>
                    </td>
                    <td className="flex">
                        <div className=" btn bg-primary my-1" onClick={() => addCultivar(index, leaf)}>
                            +
                        </div>
                        {leaf.cultivars.map((cultivar, cultivarIndex) =>
                            <div key={cultivarIndex} className="flex">
                                <SelectNumber options={cultivars[leaf.cropId] ?? []} textFunc={c => c.name ?? ''}
                                              valueFunc={c => c.id} value={cultivar}
                                              onChange={v => updateLeafs(index, {cultivars: arrayUpdate(leaf.cultivars, cultivarIndex, v)})}/>
                                <div className="m-1 text-red-500 cursor-pointer" onClick={() => {
                                    updateLeafs(index, {cultivars: arrayRemoveIndex(leaf.cultivars, cultivarIndex)});
                                }}>
                                    x
                                </div>

                            </div>
                        )}
                    </td>
                    <td>
                        <SelectNumberNullable nullableText='Cultivar specific' options={growthStages[leaf.cropId] ?? []}
                                              textFunc={g => g.name ?? ''}
                                              valueFunc={g => g.id} value={leaf.growthStageId}
                                              onChange={v => updateLeafs(index, {growthStageId: v})}/>
                    </td>
                    <td>
                        {leaf.matchedResult ?
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" className="w-6 h-6 text-primary-600 stroke-2">
                                <path
                                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" className="w-6 h-6 text-red-500 stroke-2">
                                <path
                                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                        }
                    </td>
                    <td className="text-center">
                        <CheckBox checked={leaf.excludeImage} onChange={v => updateLeafs(index, {excludeImage: v})}/>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
        <div>
            {props.extraLabSampleNumbers.length > 0 ?
                <>
                    <p>Samples received from the lab that's not in the report:</p>
                    {props.extraLabSampleNumbers.map((e, i) => <p key={i}>{e}</p>)}
                </>
                : <></>
            }
        </div>

    </div>
}


function convertHeader(data: SubmissionReportDataResponse): ReportHeader {
    return {
        submissionId: data.submissionId,
        addressId: data.address.id,
        agent: data.agent,
        agronomistId: data.agronomistId,
        clientName: data.clientName,
        distributorId: data.distributor.id,
        farmName: data.farmName,
        graphType: data.graphType,
        includeSummary: data.includeSummary,
        includeIndex: data.includeIndex,
        includeGuidelines: data.includeGuidelines,
        includeDrisGraphs: data.includeDrisGraphs,
        includeCultivarLabels: data.includeCultivarLabels,
        excludeNa: data.excludeNa,
        includeSanas: data.includeSanas,
        languageId: data.languageId
    };
}

function convertLeafs(leafs: TestLeafReportDataResponse[]): ReportLeaf[] {
    return leafs.map<ReportLeaf>(leaf => ({
        id: leaf.id,
        cropId: leaf.crop.id,
        cultivars: leaf.cultivars.map(c => c.id),
        dateCaptured: new Date(leaf.dateCaptured),
        growthStage: leaf.growthStage,
        growthStageId: leaf.growthStageId,
        matchedResult: leaf.sampleMatched,
        notes: leaf.notes,
        sample: leaf.sample,
        selected: true,
        excludeImage: false,
        dataId: leaf.dataId,
        blockNumber: leaf.blockNumber
    }))
}

// if there is no matched data.
const reportError = <Failed text="Could not generate a report, because there is no matched report data."
                            title="Could not generate report"/>;

// if there is no leafs selected 
const leafError = <Failed text="Could not generate a report, because no leafs have been selected."
                            title="No leafs selected"/>;

const Report = React.forwardRef(ReportForward);

const Reports: React.FC<{ data: PreReportData }> = (props) => {

    const context = useContext(AppContext);
    const reportRef = useRef<(ReportRef | null)[]>([])
    const [showReport, setShowReport] = useState(false);
    const [loadingIndex, setLoadingIndex] = useState<{ loading: boolean, failed: boolean, selectedIndex: number }>({loading: false, failed: false, selectedIndex: 0})
    const [reportData, setReportData] = useState<ReportDataResultEx[]>([]);
    // useRef to access from outside 
    const reportDataLength = useRef(0);
    
    const validation = useValidation({
        validClientAndFarmNames: () => {
            return reportRef.current
                .filter(r => r != null)
                .map(r => r!.requestUpdate())
                .every(s => checkIfValidForWindowsZip(s.clientName) && checkIfValidForWindowsZip(s.farmName))
        }
    })
    function downloadPdf() {
        setShowReport(false)
        window.location.href = SubmissionController.urls.questPdf(Array.from(new Set(reportData.map(r => r.leafId))));
        context.showSnack(<Success title={"Starting download"}/>)
    }
    
    useEffect(() =>{
        window.addEventListener('keydown', changePages)
        return () => window.removeEventListener('keydown', changePages)
    }, [previous, next])

    const timer = useRef<number>();
    
    function previous() {
        if (loadingIndex.selectedIndex > 0) {
            setLoadingIndex({...loadingIndex, loading: true, selectedIndex: loadingIndex.selectedIndex - 1})
        } 
    }
    
    function next() {
        if (loadingIndex.selectedIndex < reportDataLength.current - 1) {
            setLoadingIndex({...loadingIndex, loading: true, selectedIndex: loadingIndex.selectedIndex + 1})
        } 
    }

    function updateRecommendation(value: string) {
        updateCurrentItem({recommendation: value})
        if (timer.current)
            clearTimeout(timer.current)

        timer.current = window.setTimeout(() => {
            updateCurrentItem(d => ({
                ...d,
                recommendation: value,
                imageUrl: buildImageUrl(d, value, d.plantSay)
            }))
        }, 400)
    }
    
    function updateWhatDoesPlantSay(value: string){
        updateCurrentItem({plantSay: value})
        if (timer.current)
            clearTimeout(timer.current)

        timer.current = window.setTimeout(() => {
            updateCurrentItem(d => ({
                ...d,
                plantSay: value,
                imageUrl: buildImageUrl(d, d.recommendation, value)
            }))
        }, 400)
    }

    function updateReport() {
        if (!validation.validate()) return;
        
        setLoadingIndex({loading: true, failed: false, selectedIndex: 0})
        const update = reportRef.current
            .filter(r => r != null)
            .map(r => r!.requestUpdate());

        if (update.every(u => u.leafs.every(l => !l.matchedResult))) {
            if (update.every(u => u.leafs.length == 0)) {
                context.showSnack(leafError);
                return;
            }
            context.showSnack(reportError);
            return;
        }

        wrapLoader(context, SubmissionController.updateReportData(update), data => {
            if (data.length == 0) {
                context.showSnack(reportError);
                return;
            }
            setShowReport(true)
            reportDataLength.current = data.length;
            setReportData(data.map(d => ({
                ...d,
                imageUrl: buildImageUrl(d, d.recommendation, d.plantSay),
            })))
        });
    }

    function updateCurrentItem(partial: ArrayUpdateType<ReportDataResultEx>) {
        setReportData(arrayUpdatePartial(reportData, loadingIndex.selectedIndex, partial));
    }
    
    function errorLoadingImage(){
        setLoadingIndex({...loadingIndex, loading: false, failed: true})
    }

    const currentItem = useMemo<ReportDataResultEx>(() => {
        return reportData[loadingIndex.selectedIndex] ?? {
            imageUrl: '',
            leafId: 0,
            recommendation: '',
            plantSay: '',
            submissionId: 0
        };
    }, [loadingIndex.selectedIndex, reportData])

    function changePages(e: KeyboardEvent) {
        if (e.key === 'ArrowLeft') {
            previous()
        } else if (e.key === 'ArrowRight') {
            next()
        }
    }


    return <div className="p-4">
        {props.data.reports.map((report, index) =>
            <div key={report.data.id} className="border m-2 p-2 border-primary shadow-md">
                <Report
                    ref={r => reportRef.current[index] = r}
                    id={report.data.id}
                    data={props.data}
                    extraLabSampleNumbers={report.extraSampleNumbers}
                    header={convertHeader(report.data)}
                    leafs={convertLeafs(report.data.leafs)}
                />
            </div>)}

        <div className="flex items-center justify-end bg-white  p-2 border-t sticky bottom-0 ">
            <div className="btn bg-primary-500"
                 onClick={() => {
                     updateReport()
                 }}>
                Submit
            </div>
        </div>
        <Dialog title={`Page ${loadingIndex.selectedIndex + 1} of ${reportData.length}`} show={showReport} setShow={setShowReport}>
            <div className="p-2">
                {
                    loadingIndex.failed
                        ? <div className='text-2xl flex border border-red-400 p-2 my-1 rounded items-center bg-gray-50'>
                                <XCircleIcon className="h-12 w-12 text-red-400 m-2" aria-hidden="true"/>
                                <div>Could not load image</div>
                            </div>
                            :<div className="p-2">
                                <img onError={() => errorLoadingImage()} onLoad={() => setLoadingIndex({...loadingIndex, loading: false})}
                              src={currentItem.imageUrl} alt="image"/>
                            </div>
                    }
                    
                <div className='p-1 border-t sticky bottom-0 bg-white' onKeyDown={e => e.stopPropagation()}>
                    <div className="text-left">
                        <span>Wat sê jou plant?</span>
                    </div>
                    <textarea 
                        defaultValue="Wat sê jou plant?"
                        className="input mb-1" rows={3}
                        value={{...currentItem}.plantSay}
                        onChange={e => updateWhatDoesPlantSay((e.target as HTMLTextAreaElement).value)}>
                    </textarea>
                    <div className="text-left">
                        <span>Aanbeveling</span>
                    </div>
                    <textarea 
                        defaultValue=""
                        className="input mb-1" rows={3}
                        value={{...currentItem}.recommendation}
                        onChange={e => updateRecommendation((e.target as HTMLTextAreaElement).value)}>
                    </textarea>

                    <button className="btn bg-gray-500"
                            onClick={() => previous()}>Previous
                    </button>
                    <button className="btn btn-error" onClick={() => {
                        setShowReport(false)
                        setLoadingIndex({...loadingIndex, selectedIndex: 0})
                    }}>Close</button>
                    <button className="btn btn-primary"
                            onClick={() => next()}>Save
                        and Next
                    </button>
                    <button className="btn btn-primary" onClick={() => downloadPdf()}>Download PDF</button>
                </div>
                
            </div>

            {
                loadingIndex.loading ?
                    <div className="absolute inset-0 bg-overlay-200 flex justify-center items-center">
                        <img src='/images/loader.gif' alt=''/>
                    </div> : null
            }
        </Dialog>
        
    </div>
}


export default Reports;