import React, {useEffect, useRef} from "react";
import {classNames} from "../wrapper";
import TestCarohydrateMatch from "../controllers/TestCarohydrateMatch";

// unique count for each autocomplete component
let fileId = 0

const SampleNumber: React.FC<{
    value: string;
    onSampleResolve: (value: string, sample: TestCarohydrateMatch | null) => void;
    matches: TestCarohydrateMatch[];
    autoFocus?: boolean;
    onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => void;
    error?: boolean;
    className?: string;
}> = (props) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const id = useRef('SampleNumber' + fileId++)

    return (
        <div className="relative">
            <input
                ref={inputRef}
                list={id.current}
                className={classNames('peer input border', props.error ? 'border-error-300' : 'border-gray-300', props.className ? props.className : '')}
                placeholder="Click to select"
                autoFocus={props.autoFocus}
                onPaste={props.onPaste}
                value={props.value}
                onKeyDown={e => {
                    // inputKeyDown(e)
                }}
                onClick={() => {
                    inputRef.current?.select()
                }}
                onInput={e => {
                    // first resolve as null, otherwise sample number state will be slow and possible loss of data
                    const val = (e.target as HTMLInputElement).value;
                    const matched = props.matches.find(m => m.sampleNumber.trim() == val);
                    if (matched) {
                        props.onSampleResolve(val, matched);
                        return;
                    }
                    props.onSampleResolve(val, null);
                }}
            />
            <datalist id={id.current}>
                {props.matches.map(l => <option className="z-50" key={l.testCarbohydrateId}>{l.sampleNumber}</option>)}
            </datalist>

        </div>
    )
}

export default SampleNumber