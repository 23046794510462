import {classNames} from '../wrapper'
import React, {useRef, useState, useContext, PropsWithChildren, ReactNode} from 'react'
import AppContext from '../appContext'

let fileId = 0

const FileUploader: React.FC<PropsWithChildren<{
    onChange: (file: File) => void,
    fileTypes?: string
}>> = (props) => {
    const id = useRef('imageUploader' + fileId++)
    const input = useRef<HTMLInputElement>(null)
    const [highlight, setHighlight] = useState(false)
    const app = useContext(AppContext)

    function onDragEnter(event: React.DragEvent<HTMLLabelElement>) {
        setHighlight(true)
        event.preventDefault()
        event.stopPropagation()
    }

    function onDragLeave(event: React.DragEvent<HTMLLabelElement>) {
        setHighlight(false)
        event.preventDefault()
        event.stopPropagation()
    }

    function onDragOver(event: React.DragEvent<HTMLLabelElement>) {
        setHighlight(true)
        event.preventDefault()
        event.stopPropagation()
    }

    function handleFiles(files: FileList | null) {
        if (files === null || files.length === 0) {
            return
        }

        props.onChange(files[0]!)
    }

    function onDrop(event: React.DragEvent<HTMLLabelElement>) {
        handleFiles(event.dataTransfer.files)
        setHighlight(false)
        event.preventDefault()
        event.stopPropagation()
    }

    function inputChanged() {
        handleFiles(input.current!.files)
    }

    return <label
        onDragEnter={e => onDragEnter(e)}
        onDragLeave={e => onDragLeave(e)}
        onDragOver={e => onDragOver(e)}
        onDrop={e => onDrop(e)}
        htmlFor={id.current}
    >
        <input ref={input} id={id.current} accept={props.fileTypes} type="file" className="hidden"
               onChange={() => inputChanged()}/>
        {props.children ??
            <div
                className={classNames('inline-block border-dashed border relative rounded cursor-pointer w-full', highlight ? 'border-primary-600' : '')}>
                <div className="text-center">
                    <div className="line p-3 text-gray-600 text-sm">Drop or select file here</div>
                    <div className="btn bg-primary-500 my-2">browse</div>
                </div>
            </div>
        }
    </label>;
}

export default FileUploader
