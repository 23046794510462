import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import AuthController from "../controllers/AuthController";
import AppContext from "../appContext";
import {wrapLoader} from "../wrapper";
import {Link} from "react-router-dom";
import Input from "../components/Input";
import {useValidation} from "../validation";
import Dialog from "../components/Dialog";

const Forgot: React.FC<{ show: boolean, setShow: Dispatch<SetStateAction<boolean>> }> = (props) => {

    const context = useContext(AppContext)
    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false)

    const validation = useValidation({
        email: () => email.length > 0
    })

    function reset() {
        wrapLoader(context, AuthController.forgot({email}), () => {
           setSent(true)
        })
    }

    function backToLogin() {
        props.setShow(false)
        setSent(false)
    }

    return (
        <Dialog title="Forgot your password?" show={props.show} setShow={props.setShow}  
                body={
                    <div >
                    <img
                        className="mx-auto h-32 w-auto mt-2"
                        src="/images/logo.png"
                        alt="Workflow"
                    />
                    {!sent ?
            
                        <div className="py-2 px-4">
                            <h2 className="mt-6 text-center text-3xl text-primary-800">
                                Forgot your password?
                            </h2>
                            <div className="text-center py-2 text-sm">
                                Don't worry! Just fill in you email address and we'll send you a
                                link to reset.
                            </div>
            
                            <div className="rounded-md shadow-sm">
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <label htmlFor="email-address" className="sr-only">Email address</label>
                                    <Input value={email} change={v => setEmail(v)}/>
                                </div>
                            </div>
            
                            <button
                                type="submit"
                                onClick={() => reset()}
                                className="group relative w-full flex justify-center py-2 mt-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            >
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <svg
                                        className="h-5 w-5 text-primary-500 group-hover:text-primary-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </span>
                                Reset
                            </button>
                        </div> 
                    :    
                        <div>
                            <div className="text-center py-2 px-4 text-sm">
                                If your email was found you will receive a reset link shortly.
                            </div>
                        </div>
                    }
            
                    <div className="font-medium text-primary-600 hover:text-primary-500 px-4 py-2" onClick={backToLogin}>
                        Back to Login
                    </div>
                    </div>    
                }
        />)
}

export default Forgot;