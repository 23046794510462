import React, {useContext} from "react";
import AppContext from "../appContext";
import {Tab} from "@headlessui/react";
import {Fragment} from 'react'
import LeafsTab from "./leafs/leafsTab";
import PermissionEnum from "../controllers/PermissionEnum";

export function classes(selected: boolean) {
    let d = 'w-full rounded-lg text-sm font-medium leading-5 text-primary-700 ring-white ring-opacity-60 ring-offset-2 ring-offset-primary-400 focus:outline-none focus:ring-2'
    return d + (selected ? ' bg-white shadow' : ' text-primary-100 hover:bg-white/[0.12] hover:text-white');
}
const Leafs: React.FC = () => {
    const context = useContext(AppContext);
    
    return (<div className="bg-white p-2 shadow">
            <Tab.Group defaultIndex={-1}>
                <Tab.List className="flex space-x-1 rounded-xl bg-primary-500 p-1">
                        <Tab as={Fragment}>
                            {({selected}) => (
                                <button className={classes(selected)}>
                                    Not Completed
                                </button>
                            )}
                        </Tab>
                        <Tab as={Fragment}>
                            {({selected}) => (
                                <button className={classes(selected)}>
                                    Completed
                                </button>
                            )}
                        </Tab>
                    {context.hasPermission(PermissionEnum.LeafResults) ?
                        <Tab as={Fragment}>
                            {({selected}) => (
                                <button className={classes(selected)}>
                                    Results
                                </button>
                            )}
                        </Tab>
                    : null}
                        <Tab as={Fragment}>
                            {({selected}) => (
                                <button className={classes(selected)}>
                                    Archived
                                </button>
                            )}
                        </Tab>
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        <LeafsTab archived={false} results={false} notCompleted={true}/>
                    </Tab.Panel>
                    <Tab.Panel>
                        <LeafsTab archived={false} results={false} notCompleted={false}/>
                    </Tab.Panel>
                    <Tab.Panel>
                        <LeafsTab archived={false} results={true} notCompleted={false}/>
                    </Tab.Panel>
                    <Tab.Panel>
                        <LeafsTab archived={true} results={false} notCompleted={false}/>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}

export default Leafs;
