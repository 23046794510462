import { classNames } from '../wrapper'
import React, {useContext, useEffect, useRef, useState} from 'react'
import AppContext from '../appContext'

let imageId = 0

const ImageUploader: React.FC<{ imageUrl: string, setImage: (file: File, url: string) => void }> = (props) => {
    const id = useRef('imageUploader' + imageId++)
    const input = useRef<HTMLInputElement>(null)
    const [highlight, setHighlight] = useState(false)
    const [url, setUrl] = useState(props.imageUrl)
    const app = useContext(AppContext)
    
    useEffect(() => {
        setUrl(props.imageUrl)
    }, [props.imageUrl])

    function onDragEnter (event: React.DragEvent<HTMLLabelElement>) {
        setHighlight(true)
        event.preventDefault()
        event.stopPropagation()
    }

    function onDragLeave (event: React.DragEvent<HTMLLabelElement>) {
        setHighlight(false)
        event.preventDefault()
        event.stopPropagation()
    }

    function onDragOver (event: React.DragEvent<HTMLLabelElement>) {
        setHighlight(true)
        event.preventDefault()
        event.stopPropagation()
    }

    function handleFiles (files: FileList | null) {
        if (files === null || files.length === 0) { return }

        const first = files[0]!
        const reader = new FileReader()
        reader.onload = e => {
            const u = e.target!.result as string
            setUrl(u)
            props.setImage(first, u)
        }
        reader.readAsDataURL(first)
    }

    function onDrop (event: React.DragEvent<HTMLLabelElement>) {
        handleFiles(event.dataTransfer.files)
        setHighlight(false)
        event.preventDefault()
        event.stopPropagation()
    }

    function inputChanged () {
        handleFiles(input.current!.files)
    }

    return (
        <label
            onDragEnter={e => onDragEnter(e)}
            onDragLeave={e => onDragLeave(e)}
            onDragOver={e => onDragOver(e)}
            onDrop={e => onDrop(e)}
            htmlFor={id.current}
            className={classNames('inline-block border-dashed border relative rounded cursor-pointer p-2', highlight ? 'border-primary-600' : '')}>

            <div className="text-center">
                <img style={{ maxWidth: '600px' }} className="inline-block" src={url} alt=""/>
            </div>

            <input ref={input} id={id.current} type="file" className="hidden" onChange={() => inputChanged()}/>
            <div className="text-center">
                <div className="line p-3 text-gray-600 text-sm">Drop or select file here</div>
                <div className="btn bg-primary-500">browse</div>
            </div>
        </label>
    )
}

export default ImageUploader
